import clsx from 'clsx'
import { type PropsWithChildren, useEffect, useLayoutEffect, useRef, useState } from 'react'

import './style.scss'

interface CollapsibleProps {
  open: boolean
  className?: string
}

const CollapsibleContent: React.FC<CollapsibleProps & PropsWithChildren> = ({ open, children, className }) => {
  const content = useRef<HTMLDivElement>(null)
  const [height, setHeight] = useState<string>('0')
  const [internalOpen, setInternalOpen] = useState<boolean>(open)

  useLayoutEffect(() => {
    if (!open && internalOpen && content?.current) {
      setHeight(`${content.current.scrollHeight}px`)
    }

    requestAnimationFrame(() => { setInternalOpen(open) })
  }, [internalOpen, open])

  useEffect(() => {
    if (content?.current) {
      if (internalOpen) {
        // add listener to enable auto height of collapsible content to ensure height adjustment
        // due to window resizing
        content.current.ontransitionend = () => {
          setHeight('auto')
          // remove listener after animation is done
          if (content.current?.ontransitionend) {
            content.current.ontransitionend = null
          }
        }
      }
      setHeight(internalOpen ? `${content.current.scrollHeight}px` : '0')
    }
  }, [internalOpen])

  return (
        <div
            className={clsx(className, 'collapsible', { 'collapsible--open': internalOpen })}
            style={{ height }}
            ref={content}
        >
            {children}
        </div>
  )
}

export default CollapsibleContent
