import { type PropsWithChildren, useLayoutEffect, useState } from 'react'

import './style.scss'
import clsx from 'clsx'
import { Capacitor } from '@capacitor/core'
import { CLUB_IMAGE } from '../../lib/apollo/config'
// import HsvRaute from '../HsvRaute';

const AppIntro: React.FC<PropsWithChildren> = ({ children }) => {
  const [effect, setEffect] = useState(false)
  const [introDone, setIntroDone] = useState(false)
  useLayoutEffect(() => {
    if (Capacitor.isNativePlatform()) {
      setTimeout(() => {
        setEffect(true)
      }, 1000)

      setTimeout(() => {
        setIntroDone(true)
      }, 1500)
    }
  }, [])

  if (!Capacitor.isNativePlatform() || introDone) {
    return <>{children}</>
  }

  return <>
        <div className={clsx('app-intro', { effect })}>
            <img src={CLUB_IMAGE} alt='Join My Talent PRO' />
            <div className='text'>Join My Talent <b>PRO</b></div>
        </div>
        {children}
    </>
}

export default AppIntro
