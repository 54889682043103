import '../style.scss'
import {
  CampDocument,
  type Participant as GqlParticipant,
  useAddMultipleToGroupMutation,
  useRemoveMultipleFromGroupMutation,
  useCampQuery,
  useDeleteGroupMutation,
  useEditGroupMutation,
  useEditTrainerMutation,
  useParticipantsQuery
} from '../../../lib/apollo/types'
import { type RouteComponentProps } from 'react-router'
import Header from '../../../components/Header'
import Page from '../../../components/Page'
import Content from '../../../components/Content'
import { useTranslation } from 'react-i18next'
import Participant from '../../../components/Camps/Participant'
import ContextMenu from '../../../components/ContextMenu'
import ContextMenuItem from '../../../components/ContextMenu/ContextMenuItem/ContentMenuItem'
import { addCircle, checkmark, pencil, trashBin, person, removeCircle } from 'ionicons/icons'
import Modal from '../../../components/Modal'
import { useCallback, useMemo, useState } from 'react'
import Form from '../../../components/Form'
import Button from '../../../components/Button'
import {
  IonCard,
  IonCardContent,
  IonCheckbox,
  IonFab,
  IonFabButton,
  IonIcon,
  IonSelectOption,
  IonSpinner, IonText,
  useIonRouter
} from '@ionic/react'
import getAge from '../../../lib/getAge'
import FormSelectField from '../../../components/Form/FormSelectField'
import FormTextField from '../../../components/Form/FormTextField'

interface CampGroupProps
  extends RouteComponentProps<{
    id: string
    groupIndex: string
  }> {}
const GroupPage: React.FC<CampGroupProps> = ({ match }) => {
  const groupIndex = match.params.groupIndex
  const { t } = useTranslation()
  const { goBack } = useIonRouter()
  const [editOpen, setEditOpen] = useState(false)
  const [editTrainerOpen, setEditTrainerOpen] = useState(false)

  const [talentIds, setTalentIds] = useState<string[]>([])
  const [talentRemoveIds, setTalentRemoveIds] = useState<string[]>([])
  const [addOpen, setAddOpen] = useState(false)
  const [removeOpen, setRemoveOpen] = useState(false)

  const { data } = useCampQuery({
    variables: {
      campId: match.params.id
    },
    fetchPolicy: 'cache-and-network'
  })

  const { data: participants, loading } = useParticipantsQuery({
    variables: {
      campId: match.params.id
    },
    fetchPolicy: 'cache-and-network'
  })

  const [editGroup] = useEditGroupMutation()

  const group = data?.camp?.groups?.find(g => g.id === groupIndex)

  const editName = useCallback(async ({ name }: { name: string }) => {
    await editGroup({
      variables: {
        input: {
          campId: match.params.id,
          name
        },
        groupId: group?.id ?? ''
      },
      refetchQueries: [{
        query: CampDocument,
        variables: {
          campId: match.params.id
        }
      }]
    })

    setEditOpen(false)
  }, [editGroup, group?.id, match.params.id])

  const [editTrainerMutation] = useEditTrainerMutation()

  const editTrainer = useCallback(async ({ trainerId }: { trainerId: string }) => {
    await editTrainerMutation({
      variables: {
        groupId: group?.id ?? '',
        input: {
          trainerId,
          campId: match.params.id
        }
      },
      refetchQueries: [{
        query: CampDocument,
        variables: {
          campId: match.params.id
        }
      }]
    })
    setEditTrainerOpen(false)
  }, [editTrainerMutation, group?.id, match.params.id])

  const [deleteGroup] = useDeleteGroupMutation({
    refetchQueries: [{
      query: CampDocument,
      variables: {
        campId: match.params.id
      }
    }]
  })

  const onDeleteClick = useCallback(async () => {
    if (!group) return
    await deleteGroup({
      variables: {
        groupId: group.id ?? ''
      }
    })
    goBack()
  }, [deleteGroup, goBack, group])

  const onTalentItemCLick = useCallback((talentId: string) => {
    if (talentIds.includes(talentId)) {
      setTalentIds(talentIds.filter(id => id !== talentId))
    } else {
      setTalentIds([...talentIds, talentId])
    }
  }, [talentIds])

  const onTalentRemoveItemCLick = useCallback((talentId: string) => {
    if (talentRemoveIds.includes(talentId)) {
      setTalentRemoveIds(talentRemoveIds.filter(id => id !== talentId))
    } else {
      setTalentRemoveIds([...talentRemoveIds, talentId])
    }
  }, [talentRemoveIds])

  const [removeTalents] = useRemoveMultipleFromGroupMutation({
    refetchQueries: [{
      query: CampDocument,
      variables: {
        campId: match.params.id
      }
    }]
  })

  const [addTalents] = useAddMultipleToGroupMutation({
    refetchQueries: [{
      query: CampDocument,
      variables: {
        campId: match.params.id
      }
    }]
  })

  const onAddTalentsClick = useCallback(async () => {
    await addTalents({
      variables: {
        groupId: group?.id ?? '',
        campId: match.params.id,
        participantIds: talentIds
      }
    })
    setAddOpen(false)
  }, [addTalents, group?.id, match.params.id, talentIds])

  const onRemoveTalentsClick = useCallback(async () => {
    await removeTalents({
      variables: {
        groupId: group?.id ?? '',
        campId: match.params.id,
        participantIds: talentRemoveIds
      }
    })
    setRemoveOpen(false)
  }, [removeTalents, group?.id, match.params.id, talentRemoveIds])

  const { groupedParticipantsByAge, sortedParticipants } = useMemo(() => {
    if (!participants?.participants) return {}

    const groupedParticipantsByAge: Record<string, Array<GqlParticipant & { group: string }>> = {}
    const sortedParticipants: Record<string, (GqlParticipant)[]> = {}
    const sortedByName = [...participants?.participants ?? []].sort(
      (a, b) => {
        if (a.firstname.localeCompare(b.firstname) === 0) {
          return a.lastname.localeCompare(b.lastname)
        }
        return a.firstname.localeCompare(b.firstname)
      }
    )

    sortedByName.forEach((participant) => {
      const age = getAge(participant.birthday)
      if (!age) return
      // check if participant is already in group
      if (group?.participants.find(p => p.id === participant.id)) {
        if (!sortedParticipants[age]) {
          sortedParticipants[age] = []
        }
        sortedParticipants[age].push(participant)
        return
      }

      if (!groupedParticipantsByAge[age]) {
        groupedParticipantsByAge[age] = []
      }

      const groupName = data?.camp?.groups?.find(group => group.participants.find(p => p.id === participant.id))?.name ?? ''

      groupedParticipantsByAge[age].push({
        ...participant,
        group: groupName ?? ''
      })
    })

    return { groupedParticipantsByAge, sortedParticipants }
  }, [data?.camp?.groups, group?.participants, participants?.participants])

  return (
        <Page className='camp-group'>
            <Header title={data?.camp?.caption ?? t('title.camp') } />
            <Content>
                <div>
                    <h2 className='camp-group__title'>
                        {group?.name}
                        <ContextMenu>
                            <ContextMenuItem label={t('group.editTrainer')} icon={person} onClick={() => { setEditTrainerOpen(true) }}/>
                            <ContextMenuItem label={t('group.edit')} icon={pencil} onClick={() => { setEditOpen(true) }}/>
                            <ContextMenuItem alertText={t('group.deleteAlert') } label={t('group.delete')} icon={trashBin} onClick={onDeleteClick}/>
                            <ContextMenuItem label={t('group.addTalent')} icon={addCircle} onClick={() => { setAddOpen(true) }}/>
                            {(Object.entries(sortedParticipants ?? {})).length > 0 && (
                                <ContextMenuItem label={t('group.removeTalent')} icon={removeCircle} onClick={() => { setRemoveOpen(true) }}/>
                            )}
                        </ContextMenu>
                    </h2>
                    <IonText>Trainer: {group?.trainer?.firstname} {group?.trainer?.lastname}</IonText>
                </div>
                {Object.entries(sortedParticipants ?? {}).map(([age, participants]) => (
                    <div key={age} className='ion-margin-bottom ion-padding-bottom'>
                        <h3>{age} Jahre</h3>
                        <div className='camp__participant-list'>
                        {participants.map((participant) => (
                            <Participant participant={participant} campId={data?.camp?.id ?? ''} key={participant.id} />
                        ))}
                        </div>
                    </div>
                ))}
                {group?.participants.length === 0 && (
                    <IonCard onClick={() => { setAddOpen(true) }} className='camp__add-group'>
                        <IonCardContent className='camp__group-card'>
                            <div><IonIcon icon={addCircle} /></div>
                            <div>Teilnehmer hinzufügen</div>
                        </IonCardContent>
                    </IonCard>
                )}
            </Content>
            <Modal
                modalTitle='Trainer zuweisen'
                size='alert'
                isOpen={editTrainerOpen}
                onDidDismiss={() => { setEditTrainerOpen(false) }}
            >
                <Form onSubmit={editTrainer}>
                    <FormSelectField required label='Trainer' name='trainerId' className='jmt-select'>
                        {data?.camp?.trainers?.map((t) => (
                            <IonSelectOption
                                value={t.id}
                                key={t.id}
                            >
                                {t.firstname} {t.lastname}
                            </IonSelectOption>
                        ))}
                    </FormSelectField>
                    <Button type='submit' className='ion-float-end'>Speichern</Button>
                </Form>
            </Modal>
            <Modal
                modalTitle='Gruppe bearbeiten'
                size='alert'
                isOpen={editOpen}
                onDidDismiss={() => { setEditOpen(false) }}
            >
                <Form onSubmit={editName}>
                    <FormTextField name='name' label={t('camp.createGroup.label')} required />
                    <Button type='submit' className='ion-float-end'>Speichern</Button>
                </Form>
            </Modal>
            <Modal
                modalTitle='Teilnehmer auswählen'
                isOpen={addOpen}
                onClose={() => { setAddOpen(false) }}
                onDidDismiss={() => { setAddOpen(false) }}
            >
                {loading && (
                    <div className='ion-justify-content-center display-flex'>
                        <IonSpinner />
                    </div>
                )}
                {Object.entries(groupedParticipantsByAge ?? {}).map(([age, participants]) => (
                    <div key={age} className='ion-margin-bottom ion-padding-bottom'>
                        <h3>{age} Jahre</h3>
                        <div className='camp__group-list'>
                            {participants.map((participant) => (
                                <IonCard style={{ cursor: 'pointer' }} className='participant-card participant-card--full-width' onClick={() => { onTalentItemCLick(participant.id) }} key={participant.id}>
                                    <IonIcon src='/assets/icon/logo.svg' size='large'/>
                                    <div className='participant-card__caption'>
                                        {participant.firstname} {participant.lastname} <br />
                                        <span className='participant-card__caption__group'>{participant.group}</span>
                                    </div>
                                    <div className='ion-align-self-center display-flex ion-justify-content-end' style={{ flexGrow: 1 }}>
                                        <IonCheckbox checked={talentIds.includes(participant.id)} onClick={(e) => { e.preventDefault() }}/>
                                    </div>
                                </IonCard>
                            ))}
                        </div>
                    </div>
                ))}
                <IonFab vertical='bottom' horizontal='end' slot='fixed'>
                    <IonFabButton onClick={onAddTalentsClick}><IonIcon icon={checkmark}/></IonFabButton>
                </IonFab>
            </Modal>
            <Modal
                modalTitle='Teilnehmer auswählen'
                isOpen={removeOpen}
                onClose={() => { setRemoveOpen(false) }}
                onDidDismiss={() => { setRemoveOpen(false) }}
            >
                {loading && (
                    <div className='ion-justify-content-center display-flex'>
                        <IonSpinner />
                    </div>
                )}
                {Object.entries(sortedParticipants ?? {}).map(([age, participants]) => (
                    <div key={age} className='ion-margin-bottom ion-padding-bottom'>
                        <h3>{age} Jahre</h3>
                        <div className='camp__group-list'>
                            {participants.map((participant) => (
                                <IonCard style={{ cursor: 'pointer' }} className='participant-card participant-card--full-width' onClick={() => { onTalentRemoveItemCLick(participant.id) }} key={participant.id}>
                                    <IonIcon src='/assets/icon/logo.svg' size='large'/>
                                    <div className='participant-card__caption'>
                                        {participant.firstname} {participant.lastname}
                                    </div>
                                    <div className='ion-align-self-center display-flex ion-justify-content-end' style={{ flexGrow: 1 }}>
                                        <IonCheckbox checked={talentRemoveIds.includes(participant.id)} onClick={(e) => { e.preventDefault() }}/>
                                    </div>
                                </IonCard>
                            ))}
                        </div>
                    </div>
                ))}
                <IonFab vertical='bottom' horizontal='end' slot='fixed'>
                    <IonFabButton onClick={onRemoveTalentsClick}><IonIcon icon={checkmark}/></IonFabButton>
                </IonFab>
            </Modal>
        </Page>
  )
}

export default GroupPage
