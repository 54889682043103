import './JoinJMTModal.scss'
import { useCallback, useState } from 'react'
import Modal from '../../../../components/Modal'
import { IonBadge, IonCol, IonGrid, IonProgressBar, IonRow, IonText } from '@ionic/react'
import { Trans, useTranslation } from 'react-i18next'
import Buttons from '../../../../components/Buttons'
import Button from '../../../../components/Button'
import Form from '../../../../components/Form'
import FormTextField from '../../../../components/Form/FormTextField'
import Divider from '../../../../components/Divider'
import { Browser } from '@capacitor/browser'
import FormCheckbox from '../../../../components/Form/FormCheckbox'
import {
  type JmtUser,
  useCheckJmtAccountMutation,
  useCreateJmtAccountAndPushCertificateMutation,
  useLoginToJmtAccountAndPushCertificateMutation
} from '../../../../lib/apollo/types'
import { useUser } from '../../../../providers/Auth/hooks'
import FormBirthdateField from '../../../../components/Form/FormBirthdateField'
import useToast from '../../../../providers/Toast/hooks'
import { type JMTModalProps } from '../JoinJMTModal'
import { CLUB_SHORT } from '../../../../lib/apollo/config'

const JoinJMTModal: React.FC<JMTModalProps> = ({ open, close, certificate }) => {
  const { t } = useTranslation()
  useUser()
  const [progress, setProgress] = useState(0.34)

  const [step, setStep] = useState('intro')

  const [credentials, setCredentials] = useState<{ name: string, password: string }>()
  const [jmtUser, setJmtUser] = useState<JmtUser>()

  const [present] = useToast()

  const [createAccountMutation, { loading: loading1 }] = useCreateJmtAccountAndPushCertificateMutation()
  const [addToAccountMutation, { loading: loading2 }] = useLoginToJmtAccountAndPushCertificateMutation()
  const [loginToJmtMutation] = useCheckJmtAccountMutation()

  const onLoginToJmtClick = useCallback(async (form: { username: string, password: string }) => {
    const response = await loginToJmtMutation({
      variables: {
        input: {
          name: form.username,
          password: form.password
        }
      }
    })
    if (response.data?.checkJMTAccount?.id) {
      setCredentials({ name: form.username, password: form.password })
      setJmtUser(response.data.checkJMTAccount)
      setProgress(1)
      setStep('confirmAccount')
    } else {
      present(t('login.error'), 'danger')
    }
  }, [loginToJmtMutation, credentials])

  const createNewAccount = useCallback(async (form: { firstName: string, lastName: string, birthday: string, username: string, password: string }) => {
    const response = await createAccountMutation({
      variables: {
        input: {
          club: CLUB_SHORT,
          jmtPassword: form.password,
          jmtUsername: form.username,
          campId: certificate.camp.id,
          participantId: certificate.participantId,
          firstName: form.firstName,
          lastName: form.lastName,
          birthday: form.birthday
        }
      }
    })

    if (response.data?.createJMTAccountAndPushCertificate) {
      setStep('success')
    } else {
      present(t('createNewAccount.error'), 'danger')
    }
  }, [certificate.camp.id, certificate.participantId, createAccountMutation, present, t, credentials])

  const addToAccount = useCallback(async () => {
    const response = await addToAccountMutation({
      variables: {
        input: {
          jmtPassword: credentials?.password ?? '',
          jmtUsername: credentials?.name ?? '',
          campId: certificate.camp.id,
          participantId: certificate.participantId,
          firstName: certificate.firstName,
          lastName: certificate.lastName,
          birthday: certificate.birthday,
          club: CLUB_SHORT
        }
      }
    })

    if (response.data?.loginToJMTAccountAndPushCertificate) {
      setStep('success')
    } else {
      present(t('createNewAccount.error'), 'danger')
    }
  }, [addToAccountMutation, certificate.birthday, certificate.camp.id, certificate.firstName, certificate.lastName, certificate.participantId, present, t, credentials])

  return (
        <Modal
            size='large'
            isOpen={open}
            onClose={close}
            onDidDismiss={close}
            modalTitle={t('cert.jmt.title') }
            className='cert-page'
        >
            {t('jmt.maintenance.status') === 'true'
              ? (
                <IonGrid>
                    <IonRow>
                        <IonCol className='ion-text-center'>
                            <h2>{t('jmt.maintenance.headline')}</h2>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonText>
                            <p className='n2br ion-margin-top'>
                                {t('jmt.maintenance.text')}
                            </p>
                        </IonText>
                    </IonRow>
                </IonGrid>
                )
              : (
                    <IonGrid>
                        <IonRow>
                            <IonCol className='ion-text-center'><IonBadge mode='ios'>1</IonBadge></IonCol>
                            <IonCol className='ion-text-center'><IonBadge mode='ios'>2</IonBadge></IonCol>
                            <IonCol className='ion-text-center'><IonBadge mode='ios'>3</IonBadge></IonCol>
                            <IonProgressBar value={progress}></IonProgressBar>
                        </IonRow>
                        {step === 'intro' && (
                            <>
                                <IonRow>
                                  <IonCol sizeMd='8' offsetMd='2'>
                                    <h2>{t('cert.jmt.intro.title')}</h2>
                                    <IonText>
                                      <p className='n2br ion-margin-top'>{t('cert.jmt.intro.subtitle')}</p>
                                    </IonText>
                                    <Buttons
                                        className='ion-margin-bottom'
                                    >
                                      <Button onClick={() => {
                                        setProgress(0.68)
                                        setStep('create')
                                      }}>{t('cert.createJmtAccount.button')}</Button>
                                    </Buttons>
                                    <Divider dark/>
                                    <h2>{t('cert.jmt.hasAccount.title')}</h2>
                                    <IonText>
                                      <p className='n2br ion-margin-top'>{t('cert.jmt.hasAccount.question')}</p>
                                    </IonText>
                                    <Buttons
                                        className='ion-margin-bottom-margin'
                                    >
                                      <Button
                                          color='jmt'
                                          onClick={() => {
                                            setProgress(0.68)
                                            setStep('login')
                                          }
                                          }>{t('cert.button.login')}</Button>
                                    </Buttons>
                                  </IonCol>
                                </IonRow>
                            </>
                        )}
                      {step === 'login' && (
                          <>
                            <IonRow className='ion-align-items-center'>
                                    <IonCol>
                                        <Button onClick={() => {
                                          setProgress(0.34)
                                          setStep('intro')
                                        }}>{t('button.back')}</Button>
                                    </IonCol>
                                </IonRow>
                                <IonRow className='ion-align-items-center'>
                                    <IonCol size='6' offset='3'>
                                        <h2>{t('cert.jmt.login.title')}</h2>
                                        <p>{t('cert.jmt.login.description')}</p>
                                    </IonCol>
                                </IonRow>
                                <IonRow>
                                    <IonCol size='6' offset='3'>
                                        <IonRow>
                                            <Form
                                                className='join-jmt-modal join-jmt-modal__login-form'
                                                onSubmit={onLoginToJmtClick}
                                            >
                                                <FormTextField name='username' label={t('login.username')} required />
                                                <FormTextField type='password' name='password' label={t('login.password')} required />
                                                <Button type={'submit'} color='jmt' iconSrc='/assets/logo_jmt.svg'>Login</Button>
                                            </Form>
                                        </IonRow>
                                    </IonCol>
                                </IonRow>
                            </>
                      )}
                        {step === 'create' && (
                            <>
                                <IonRow>
                                    <IonCol>
                                        <Button onClick={() => {
                                          setProgress(0.34)
                                          setStep('intro')
                                        }}>{t('button.back')}</Button>
                                    </IonCol>
                                </IonRow>
                                <IonRow>
                                    <IonCol size='12'>
                                        <h2>{t('cert.jmt.createAccount.title')}</h2>
                                        <IonText>
                                        <p className='n2br'>{t('cert.jmt.createAccount.description')}</p>
                                        </IonText>
                                    </IonCol>
                                    <IonCol size='12'>
                                        <Form
                                            className='join-jmt-modal join-jmt-modal__login-form'
                                            onSubmit={(v) => {
                                              setStep('createAccount')
                                            }}
                                        >
                                            <IonText>
                                            <Trans
                                                i18nKey='onboarding.termsAndConditionsText'
                                            >
                                                Zur Verwendung von Join My Talent musst du die
                                                <IonText
                                                    className='color-jmt cursor-pointer'
                                                    onClick={async () => {
                                                      await Browser.open({
                                                        url: 'https://joinmytalent.com/nutzungbedingungen/'
                                                      })
                                                    }
                                                }
                                                >
                                                    Nutzungsbedingungen
                                                </IonText>
                                                akzeptieren. Bitte lies diese sorgfältig durch.
                                            </Trans>
                                            </IonText>
                                            <FormCheckbox
                                                color='primary'
                                                required
                                                name='termsAccepted'
                                                label={t('onboarding.acceptTermsAndConditions')}
                                            />
                                            <div
                                                className='ion-padding-top ion-margin-top'
                                            >
                                                <IonText>
                                                    {t('onboarding.atLeast16Text')}
                                                </IonText>
                                            </div>
                                            <FormCheckbox
                                                color='primary'
                                                required
                                                name='atLeast16'
                                                label={t('onboarding.atLeast16')}
                                            />
                                            <Button type='submit' color='jmt' iconSrc='/assets/logo_jmt.svg'>
                                                {t('onboarding.next')}
                                            </Button>
                                        </Form>
                                    </IonCol>
                                </IonRow>
                            </>
                        )}
                        {step === 'confirmAccount' && (
                            <>
                                <IonRow>
                                    <IonCol>
                                        <Button onClick={() => {
                                          setProgress(0.34)
                                          setStep('intro')
                                        }}>{t('button.back')}</Button>
                                    </IonCol>
                                </IonRow>
                                <IonRow>
                                    <IonCol sizeMd='8' offsetMd='2' className='ion-margin-bottom ion-padding-bottom'>
                                      <h2>{t('cert.jmt.confirmAccount.title')}</h2>
                                      <div className='n2br'>
                                        <IonText>{t('cert.jmt.confirmAccount.description', { name: `${jmtUser?.firstName} ${jmtUser?.lastName}` })}</IonText>
                                      </div>
                                      <Form
                                        className='join-jmt-modal join-jmt-modal__login-form'
                                        onSubmit={addToAccount}
                                      >
                                        <Buttons>
                                          <Button loading={loading1 || loading2} type='submit' color='jmt' iconSrc='/assets/logo_jmt.svg'>
                                            {t('cert.confirmAccount.submit')}
                                          </Button>
                                        </Buttons>
                                      </Form>
                                    </IonCol>
                                </IonRow>
                            </>
                        )}
                        {(step === 'createAccount') && (
                            <>
                              <IonRow>
                                <IonCol>
                                  <Button onClick={() => {
                                    setProgress(0.34)
                                    setStep('intro')
                                  }}>{t('button.back')}</Button>
                                </IonCol>
                              </IonRow>
                              <IonRow>
                                  <IonCol sizeMd='6' offsetMd='3'>
                                      <h2>{t('cert.jmt.createNew.title')}</h2>
                                      <div className='n2br'>
                                          <IonText color='medium'>
                                              {t('cert.jmt.createNew.description')}
                                          </IonText>
                                      </div>
                                      <Form
                                          onSubmit={createNewAccount}
                                      >
                                          <FormTextField
                                              name='username'
                                              label={t('registration.username')}
                                          />
                                          <FormTextField
                                              name='password'
                                              type='password'
                                              label={t('registration.password')}
                                          />
                                          <FormTextField
                                              name='firstName'
                                              label={t('registration.firstName')}
                                              defaultValue={certificate.firstName}
                                          />
                                          <FormTextField
                                              name='lastName'
                                              label={t('registration.lastName')}
                                              defaultValue={certificate.lastName}
                                          />
                                          <FormBirthdateField
                                              name='birthday'
                                              label={t('user.birthdate')}
                                              defaultValue={certificate.birthday}
                                          />
                                          <Button loading={loading1 || loading2} type='submit' color='jmt' iconSrc='/assets/logo_jmt.svg'>
                                              {t('cert.createAccount.submit')}
                                          </Button>
                                      </Form>
                                  </IonCol>
                              </IonRow>
                            </>
                        )}
                        {(step === 'success') && (
                            <IonRow>
                                <IonCol
                                    className='ion-padding-top ion-margin-top'
                                >
                                    <h2>{t('cert.jmt.success.title')}</h2>
                                    <div className='n2br'>
                                        <IonText color='medium'>
                                            {t('cert.jmt.success.description')}
                                        </IonText>
                                    </div>
                                    <Buttons>
                                        <Button
                                            iconSrc='/assets/logo_jmt.svg'
                                            color='jmt'
                                            href='https://app.joinmytalent.com/logout'
                                        >
                                            {t('cert.success.toJMT')}
                                        </Button>
                                    </Buttons>
                                </IonCol>
                            </IonRow>
                        )}
                    </IonGrid>
                )}
        </Modal>
  )
}

export default JoinJMTModal
