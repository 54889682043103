import clsx from 'clsx'
import { useMemo, useState } from 'react'

import { createPieGraphs } from './talentIdUtils'

interface PieProps {
  values: number[]
  compareValues?: number[]
  id: string
}

const Pie: React.FC<PieProps> = ({ id, values, compareValues }) => {
  const graphs = useMemo(() => createPieGraphs([values])[0], [values])
  const graphs2 = useMemo(() => compareValues ? createPieGraphs([compareValues])[0] : null, [compareValues])
  const [activeRadar, setActiveRadar] = useState<number>()

  if (!graphs2) {
    return (
            <>
                {graphs.map((graph, idx) => (
                    <g
                        className='emoVal l-pie'
                        onMouseEnter={() => { setActiveRadar(idx) }}
                        onMouseLeave={() => { setActiveRadar(undefined) }}
                        // eslint-disable-next-line react/no-array-index-key
                        key={`${graph}-${idx}`}
                    >
                        <path
                            key={graph}
                            d={graph}
                            transform='translate(250,250)'
                            className={clsx('path--color1', { active: activeRadar === idx })}
                        />
                    </g>
                ))}
                {graphs.map((graph, idx) => (
                    <text
                        dy='.35em'
                        x='250'
                        y='250'
                        // eslint-disable-next-line react/no-array-index-key
                        key={`${graph}-${idx}-text`}
                        className={clsx('emoVal l-pie text', { active: activeRadar === idx })}
                    >
                        {`${Math.floor(values[idx])}%`}
                    </text>
                ))}
            </>
    )
  }

  return (
        <>
            {graphs.map((graph, i) => (
                <g
                    // eslint-disable-next-line react/no-array-index-key
                    key={`${graph}-${i}`}
                    transform='translate(250, 250)'
                    onMouseEnter={() => { setActiveRadar(i) }}
                    onMouseLeave={() => { setActiveRadar(undefined) }}
                >
                    <defs>
                        <mask
                            id={`${id}-${i}-pie-background`}
                        >
                            <rect
                                x='-300'
                                y='-300'
                                width='600'
                                height='600'
                                style={{ fill: 'white' }}
                            />
                            <path
                                d={graphs2[i]}
                                style={{ fill: 'black', fillOpacity: 1 }}
                            />
                        </mask>

                        <mask
                            id={`${id}-${i}-pie-foreground`}
                        >
                            <rect
                                x='-300'
                                y='-300'
                                width='600'
                                height='600'
                                style={{ fill: 'white' }}
                            />
                            <path
                                d={graph}
                                style={{ fill: 'black', fillOpacity: 1 }}
                            />
                        </mask>

                        <mask
                            id={`${id}-${i}-pie-top`}
                        >
                            <path
                                d={graph}
                                style={{ fill: 'white', fillOpacity: 1 }}
                            />
                        </mask>
                    </defs>

                    <path
                        d={graphs2[i]}
                        mask={`url(#${id}-${i}-pie-foreground`}
                        className='path--color2'
                    />
                    <path
                        d={graph}
                        mask={`url(#${id}-${i}-pie-background`}
                        className={clsx('path--color1', { active: activeRadar === i })}
                    />
                    <path
                        d={graphs2[i]}
                        mask={`url(#${id}-${i}-pie-top`}
                        className='path--overlap'
                    />

                    <path
                        d={graph}
                        className='path--combined'
                    />
                </g>
            ))}
            {graphs.map((graph, idx) => (
                <text
                    dy='.35em'
                    x='250'
                    y='250'
                    // eslint-disable-next-line react/no-array-index-key
                    key={`${graph}-${idx}-text`}
                    className={clsx('emoVal l-pie text', { active: activeRadar === idx })}
                >
                    {`${Math.floor(values[idx])}%`}
                </text>
            ))}
        </>
  )
}

export default Pie
