import './CampCard.scss'
import { type Camp } from '../../lib/apollo/types'
import { IonCard, IonCardContent, IonCardTitle } from '@ionic/react'
import Button from '../Button'
import { useTranslation } from 'react-i18next'

interface SmallCampCardProps {
  camp: Camp
  onClick: () => any
  action: string
}

const CampCardSmall = ({ camp, action, onClick }: SmallCampCardProps) => {
  const { t } = useTranslation()

  return (
        <div className='camp-card camp-card--small'>
            <IonCard className='camp-card camp-card--small'>
                <img src={camp.image ?? ''} alt={camp.caption ?? 'camp-img'} />
                <IonCardTitle mode='md'>
                    {camp.caption}
                </IonCardTitle>
                <IonCardContent className='camp-card__conent'>
                    <div>{action}</div>
                    <Button buttonSize='small' onClick={onClick}>{t('camp.goTo')}</Button>
                </IonCardContent>
            </IonCard>
        </div>
  )
}

export default CampCardSmall
