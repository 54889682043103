import { type PropsWithChildren } from 'react'
import { useUser } from '../../providers/Auth/hooks'

const Protected: React.FC<PropsWithChildren & { role: string }> = ({ children, role }) => {
  const user = useUser()

  if (!user || !user.user.roles.includes(role)) return null

  return <>{children}</>
}

export default Protected
