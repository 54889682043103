import './style.scss'
import clsx from 'clsx'

interface DividerProps {
  margin?: number
  dark?: boolean
}

const Divider: React.FC<DividerProps> = ({ margin, dark }) => {
  return (
        <hr
            style={{ marginTop: margin, marginBottom: margin }}
            className={clsx('divider', { divider__dark: dark })}
        />
  )
}

export default Divider
