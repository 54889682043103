import { type Group } from '../../lib/apollo/types'
import { IonBadge, IonCard, IonCardContent, IonCardTitle, IonIcon, useIonAlert } from '@ionic/react'
import { person } from 'ionicons/icons'
import getAge from '../../lib/getAge'
import { useTranslation } from 'react-i18next'

const getAges = (group: Group): string => {
  const years = group.participants?.map(participant => getAge(participant.birthday)).filter(Boolean)
  if (!years?.length) return ''

  years.sort((a, b) => (a ?? 0) - (b ?? 0))

  if (years[0] === years[years.length - 1]) return `${years[0]} Jahre`

  return `${years[0]} Jahre - ${years[years.length - 1]} Jahre`
}

const GroupCard: React.FC<{ group: Group, campId: string, onClick?: (g: Group) => any }> = ({ group, campId, onClick }) => {
  const { t } = useTranslation()
  const [present] = useIonAlert()
  const hasMissingImages = group.participants?.some(participant => !participant.image)

  const onBadgeClick = (e: React.MouseEvent) => {
    e.stopPropagation()
    e.preventDefault()
    if (hasMissingImages && group.missingVotes) {
      present(t('camp.groupMissingImagesAndVotes_tip'))
    } else if (group.missingVotes) {
      present(t('camp.groupMissingVotes_tip'))
    } else if (hasMissingImages) {
      present(t('camp.groupMissingImages_tip'))
    }
  }

  return (
        <IonCard className='camp__group' onClick={onClick ? () => onClick(group) : undefined} key={group.name} routerLink={!onClick ? `/camps/${campId}/group/${group.id}` : undefined}>
            {(group.missingVotes ?? hasMissingImages) && (<IonBadge onClick={onBadgeClick} className='camp__group__missing-votes' color='danger'>!</IonBadge>)}
            <IonCardTitle className='camp__group-title'>{group.name}</IonCardTitle>
            <IonCardContent className='camp__group-card'>
                <div className='camp__group-card__trainer'><IonIcon icon={person} /> {group.trainer?.firstname} {group.trainer?.lastname}</div>
                <div>{group.participants.length} Teilnehmer</div>
                <div>{getAges(group)}</div>
            </IonCardContent>
        </IonCard>
  )
}

export default GroupCard
