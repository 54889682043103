import './style.scss'
import { useMyCampsQuery } from '../../lib/apollo/types'
import CampCard from './CampCard'
import Slider from '../Slider'
import { format } from 'date-fns'
import Divider from '../Divider'

let today = new Date()
const firstJuly = new Date(2023, 6, 1)
if (today < firstJuly) {
  today = firstJuly
}
const dateInTwoWeeks = new Date(+today)
dateInTwoWeeks.setDate(today.getDate() + 90)

const Camps: React.FC = () => {
  const { data } = useMyCampsQuery({
    variables: {
      start: format(today, 'yyyy-MM-dd'),
      end: format(dateInTwoWeeks, 'yyyy-MM-dd')
    },
    fetchPolicy: 'cache-and-network'
  })

  if (!data?.myCamps || data.myCamps.length === 0) {
    return null
  }

  return (
        <>
            <Slider
                title={'Meine Camps'}
                itemWidth={240}
                freeMode={true}
            >
                {data?.myCamps?.map(camp => (
                    <CampCard key={camp.id} camp={camp} />
                ))}
            </Slider>
            <Divider />
        </>
  )
}

export default Camps
