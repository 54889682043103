import { useApolloClient } from '@apollo/client'
import type { RefresherEventDetail } from '@ionic/react'
import { IonContent, IonRefresher, IonRefresherContent } from '@ionic/react'
import clsx from 'clsx'
import { forwardRef, type PropsWithChildren, useCallback } from 'react'

import { WhoamiDocument } from '../../lib/apollo/types'
import { useUser } from '../../providers/Auth/hooks'
import useNetworkStatus from '../../lib/hooks/useNetworkStatus'

import './style.scss'

const Content = forwardRef<HTMLIonContentElement | null, React.ComponentProps<typeof IonContent> & PropsWithChildren>(({ children, ...props }, ref) => {
  const client = useApolloClient()
  const user = useUser()
  const status = useNetworkStatus()

  const handleRefresh = useCallback(async (event: CustomEvent<RefresherEventDetail>) => {
    if (!['none', 'unknown'].includes(status)) {
      const savedUser = { ...user }
      client.onResetStore(async () => {
        client.writeQuery({
          query: WhoamiDocument,
          data: { whoami: savedUser }
        })
      })
      try {
        await client.resetStore()
      } catch (e) {
        // do nothing
      }
    }

    setTimeout(() => {
      event.detail.complete()
    }, 600)
  }, [status, user, client])

  return (
        <IonContent
            ref={ref}
            {...props}
            className={clsx('content', props.className)}
        >
            <IonRefresher
                slot='fixed'
                onIonRefresh={handleRefresh}
            >
                <IonRefresherContent
                    refreshingSpinner='circular'
                    color='secondary'
                />
            </IonRefresher>

            { children }
        </IonContent>
  )
})

Content.displayName = 'Content'

export default Content
