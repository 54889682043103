import { IonIcon, IonItem, IonLabel, useIonAlert } from '@ionic/react'
import clsx from 'clsx'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useContext } from 'use-context-selector'

import { ContextMenuContext } from '../context'

interface ContextMenuItemProps {
  onClick?: (event: any) => void
  routerLink?: string
  alertText?: string | null
  label: string
  icon: string
  lines?: 'full' | 'inset' | 'none'
  disabled?: boolean
  disableCloseContextMenuOnClick?: boolean
  underlined?: boolean
  onClose?: () => void
}

const ContextMenuItem: React.FC<ContextMenuItemProps> = ({
  disabled = false,
  onClick: oC,
  onClose,
  alertText,
  label,
  icon,
  lines = 'none',
  disableCloseContextMenuOnClick = false,
  underlined = false,
  routerLink
}) => {
  const { t } = useTranslation()
  const [present] = useIonAlert()

  const context = useContext(ContextMenuContext)

  const onClick = useCallback((e: any) => {
    if (!disableCloseContextMenuOnClick) {
      context.closeContextMenu(onClose)
    }

    if (routerLink) return

    if (!alertText && oC) {
      oC(e)
    } else if (alertText) {
      present({
        message: alertText,
        buttons: [
          t('buttons.cancel'),
          { text: t('buttons.ok'), handler: oC }
        ]
      })
    }
  }, [disableCloseContextMenuOnClick, routerLink, alertText, oC, context, onClose, present, t])

  return (
        <IonItem
            className='ion-text-left context-menu-item'
            disabled={disabled}
            onClick={onClick}
            routerLink={routerLink}
            detail={false}
            button
            lines={lines}
        >
            <IonLabel
                className={clsx({ underlined })}
            >
                <IonIcon
                    icon={icon}
                />
                { label }
            </IonLabel>
        </IonItem>
  )
}

export default ContextMenuItem
