import './style.scss'
import Header from '../../../components/Header'
import Page from '../../../components/Page'
import Content from '../../../components/Content'
import { IonText, useIonAlert, useIonRouter } from '@ionic/react'
import {
  type Certificate,
  SpecialTalentsDocument,
  useCampQuery,
  useSetImageMutation,
  useTalentQuery
} from '../../../lib/apollo/types'
import { type RouteComponentProps } from 'react-router'
import Form from '../../../components/Form'
import FormImageInput from '../../../components/Form/FormImageInput'
import { save, trashBin } from 'ionicons/icons'
import { useCallback, useEffect, useMemo, useState } from 'react'
import CertificateTalentCardMedium from '../../../components/CertificateTalentCardMedium'
import useRating from '../../../lib/hooks/useRating'
import Button from '../../../components/Button'
import useToast from '../../../providers/Toast/hooks'
import Buttons from '../../../components/Buttons'
import { useTranslation } from 'react-i18next'
import example from '../../../assets/img/camera_example.png'
import { Capacitor } from '@capacitor/core'
import { CLUB_IMAGE, SCHOOL_IMAGE } from '../../../lib/apollo/config'

interface CameraPageProps
  extends RouteComponentProps<{
    id: string
    talentId: string
  }> {
}

const CameraPage: React.FC<CameraPageProps> = ({ match }) => {
  const { t } = useTranslation()
  const [preview, setPreview] = useState<string | undefined>()
  const [showSave, setShowSave] = useState<boolean>(false)

  const { rating } = useRating(match.params.talentId)

  const { data: camp } = useCampQuery({
    variables: {
      campId: match.params.id
    },
    skip: !match.params.id
  })

  const { data: talent } = useTalentQuery({
    variables: {
      id: match.params.talentId
    },
    skip: !match.params.talentId
  })

  const { goBack } = useIonRouter()

  useEffect(() => {
    if (talent?.talent?.image) {
      setPreview(talent?.talent?.image)
      setShowSave(false)
    } else {
      setPreview(undefined)
      setShowSave(false)
    }
  }, [talent?.talent?.image])

  const certificateProps: Certificate | undefined = useMemo(() => {
    if (!talent?.talent || !camp?.camp || !talent.talent.birthday) return undefined
    return {
      id: talent.talent.id,
      participantId: match.params.talentId,
      caption: `${talent.talent.firstname} ${talent.talent.lastname}`,
      birthday: talent.talent.birthday,
      image: preview,
      vote: rating,
      camp: camp.camp,
      firstName: talent.talent.firstname,
      lastName: talent.talent.lastname,
      talentClass: talent.talent.talentClass ?? 1
    }
  }, [camp?.camp, match.params.talentId, preview, rating, talent?.talent])

  const [setImage] = useSetImageMutation({
    refetchQueries: [
      {
        query: SpecialTalentsDocument,
        variables: {
          campId: match.params.id
        }
      }
    ]
  })
  const [present] = useToast()

  const onSubmit = useCallback(async ({ image }: { image: string }) => {
    await setImage({
      variables: {
        input: {
          participantId: match.params.talentId,
          image
        }
      }
    })
    present('Bild gespeichert', 'success')
    goBack()
  }, [goBack, match.params.talentId, present, setImage])

  const [alert] = useIonAlert()

  const onDeleteImage = useCallback(() => {
    alert({
      message: 'Foto wirklich löschen?',
      buttons: [
        t('buttons.cancel'),
        {
          text: t('buttons.ok'),
          handler: async () => {
            await setImage({
              variables: {
                input: {
                  participantId: match.params.talentId,
                  image: undefined
                }
              }
            })
            present('Bild gespeichert', 'success')
            setPreview(undefined)
          }
        }
      ]
    })
  }, [alert, match.params.talentId, present, setImage, t])

  return (
        <Page className="camera">
            <Header title={talent?.talent?.firstname ? `${talent?.talent?.firstname} ${talent?.talent?.lastname}` : '...'}></Header>
            <Content>
                <div>
                    <Form
                        className='display-flex-column ion-align-items-center'
                        onSubmit={onSubmit}
                    >
                        {preview && certificateProps && (
                            <CertificateTalentCardMedium
                                clubImg={CLUB_IMAGE}
                                schoolImg={SCHOOL_IMAGE}
                                certificate={certificateProps}
                            />
                        )}
                        {!preview && (
                            <div className='camera__example'>
                                <IonText>{t('image.noImage')}</IonText><br/>
                                <IonText color='medium' className='n2br'>{t('image.description')}</IonText>
                                <img src={example} alt='example' className='camera__example__image' />
                            </div>
                        )}
                        <div className='display-flex ion-align-items-center camera__buttons'>
                            <Buttons>
                                <FormImageInput
                                    name="image"
                                    label={Capacitor.isNativePlatform() ? t('talent.makeImage') : t('talent.uploadImage')}
                                    size={{ width: 800, height: 800 }}
                                    onPreviewChange={p => {
                                      setPreview(p)
                                      if (!p) {
                                        setShowSave(false)
                                      } else {
                                        setShowSave(p !== talent?.talent?.image)
                                      }
                                    }}
                                    showPreview={false}
                                    required
                                    avatarWrapperClassName='avatar-wrapper--for-talent'
                                />
                                {showSave && (
                                    <Button
                                        type='submit'
                                        icon={save}
                                        disabled={!preview}
                                    >
                                        Speichern
                                    </Button>
                                )}
                                {talent?.talent?.image && (
                                    <Button
                                        onClick={onDeleteImage}
                                        icon={trashBin}
                                        color='danger'
                                    >
                                        Bild löschen
                                    </Button>
                                )}
                            </Buttons>
                        </div>
                    </Form>
                </div>
            </Content>
        </Page>
  )
}

export default CameraPage
