import type { PredefinedColors, ToastOptions, IonicSafeString } from '@ionic/core'
import { useIonToast } from '@ionic/react'
import type { HookOverlayOptions } from '@ionic/react/dist/types/hooks/HookOverlayOptions'
import { checkmark, information, warning } from 'ionicons/icons'
import { type PropsWithChildren, useEffect, useMemo, useState } from 'react'
import { createContext } from 'use-context-selector'

const getIcon = (color: PredefinedColors): string => {
  switch (color) {
    case 'warning':
    case 'danger': {
      return warning
    }
    case 'success': {
      return checkmark
    }
    default: {
      return information
    }
  }
}

export type ToastProviderType = [(message: string | IonicSafeString | null, color: PredefinedColors, options?: ToastOptions & HookOverlayOptions) => void, () => void]

export const Toast = createContext<ToastProviderType>([
  () => {},
  () => {}
])

const useToast = (): ToastProviderType => {
  const [toasts, setToasts] = useState<Array<ToastOptions & HookOverlayOptions>>([])
  const [present, dismiss] = useIonToast()

  useEffect(() => {
    if (toasts.length > 0) {
      requestAnimationFrame(() => {
        present(toasts[0])
      })
    }
  }, [present, toasts, toasts.length])

  return useMemo(() => [(message: string | IonicSafeString | null, color: PredefinedColors, options?: ToastOptions & HookOverlayOptions) => {
    setToasts((t) =>
      [...t, {
        message: message ?? '',
        buttons: [{ icon: getIcon(color), handler: async () => { await dismiss() } }],
        color,
        duration: 3000,
        position: 'top',
        ...options,
        onDidDismiss: () => {
          setToasts(([, ...state]) => state)
        }
      }
      ])
  }, dismiss], [setToasts, dismiss])
}

export const ToastProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [present, dismiss] = useToast()
  return (
        <Toast.Provider
            value={[present, dismiss]}
        >
            {children}
        </Toast.Provider>
  )
}

export default Toast
