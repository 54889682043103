import { ApolloClient, ApolloLink, HttpLink, InMemoryCache, type ServerError } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { onError } from '@apollo/client/link/error'
import { Preferences } from '@capacitor/preferences'
import { createNetworkStatusNotifier } from 'react-apollo-network-status'
import { persistCache, IonicStorageWrapper } from 'apollo3-cache-persist'
import { Storage } from '@ionic/storage'
import STORAGE_KEYS from '../storageKeys'

import { GRAPHQL_URL } from './config'
import handleCollection from './helper/handleCollection'
import { WhoamiDocument } from './types'
import { signOut } from '../../providers/Auth/hooks'
import { Capacitor } from '@capacitor/core'

const { link, useApolloNetworkStatus: uANS } = createNetworkStatusNotifier()

export const useApolloNetworkStatus = uANS

const errorLink = onError((error) => {
  // eslint-disable-next-line no-console
  console.log({ error })

  if (window.location.href.includes('/s/') || window.location.href.includes('/login')) {
    return
  }

  console.log(error.graphQLErrors)

  error.graphQLErrors?.forEach(e => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    if (e.extensions?.response?.statusCode === 401) {
      cache.writeQuery({
        query: WhoamiDocument,
        data: { whoami: null }
      })
      signOut()
    }
  })

  if ((error.networkError as ServerError)?.statusCode === 401) {
    cache.writeQuery({
      query: WhoamiDocument,
      data: { whoami: null }
    })
    signOut()
  }
})

const authLink = setContext(async (req, { headers }) => {
  const token = await Preferences.get({ key: STORAGE_KEYS.API.LOGIN_TOKEN })
  const additionalHeaders: any = {}
  if (token?.value) {
    additionalHeaders.authorization = `Bearer ${token.value}`
  }
  return {
    headers: {
      ...headers,
      ...additionalHeaders
    }
  }
})

const graphqlLink = new HttpLink({
  uri: GRAPHQL_URL
})

const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        activities: handleCollection(),
        playlists: handleCollection(),
        playlistTalents: handleCollection(),
        networks: handleCollection(),
        listMyTalents: handleCollection(),
        similarNetwork: handleCollection(),
        networkUsers: handleCollection(),
        networkTalents: handleCollection()
      }
    },
    UserMetaData: {
      keyFields: ['username']
    },
    Talent: {
      fields: {
        dataViews: {
          merge (existing, incoming) {
            if (existing?.length > incoming?.length) {
              return existing
            }
            return incoming
          }
        },
        publicRating: {
          merge (existing, incoming) {
            if (incoming.length === 0) {
              return existing
            }
            return incoming
          }
        },
        rateCount: {
          merge (existing, incoming) {
            if (!incoming) {
              return existing
            }
            return incoming
          }
        }
      }
    },
    Network: {
      fields: {
        networkRoles: {
          merge (existing, incoming) {
            if (!incoming || incoming.length === 0 || existing?.length > incoming?.length) {
              return existing
            }
            return incoming
          }
        }
      }
    }
  }
})

// Set up your client
const apolloClient = async () => {
  if (Capacitor.isNativePlatform()) {
    const store = new Storage()
    await store.create()
    await persistCache({
      cache,
      storage: new IonicStorageWrapper(store)
    })
  }

  return new ApolloClient({
    cache,
    link: ApolloLink.from([
      errorLink,
      link,
      authLink,
      // restLink,
      graphqlLink
    ]),
    uri: GRAPHQL_URL
  })
}

export default apolloClient
