import { Plugins, Capacitor } from '@capacitor/core'
import { type PropsWithChildren, useEffect } from 'react'
import { useForm, FormProvider } from 'react-hook-form'
import type { UseFormReturn } from 'react-hook-form/dist/types'
import type { ErrorOption } from 'react-hook-form/dist/types/errors'
import type { Mode } from 'react-hook-form/dist/types/form'

interface FormProps {
  onSubmit: (e: any, formCtx: UseFormReturn) => any
  errors?: Array<{ name: string, error: ErrorOption }>
  reValidateMode?: 'onChange' | 'onBlur' | 'onSubmit'
  mode?: Mode
  autoComplete?: 'off' | 'on'
  accessoryBarVisible?: boolean
  className?: string
}

const { Keyboard } = Plugins

const Form: React.FC<FormProps & PropsWithChildren> = ({
  children,
  onSubmit,
  errors,
  reValidateMode = 'onSubmit',
  mode,
  accessoryBarVisible = false,
  autoComplete,
  className
}) => {
  const formCtx = useForm({
    reValidateMode,
    mode
  })

  useEffect(() => {
    errors?.forEach(({ name, error }) => {
      formCtx.setError(name, error)
    })
  }, [errors, formCtx])

  useEffect(() => {
    if (Capacitor.isNativePlatform()) {
      Keyboard.setAccessoryBarVisible({ isVisible: accessoryBarVisible })
    }
  }, [accessoryBarVisible])

  return (
        <FormProvider
            {...formCtx}
        >
            <form
                className={className}
                autoComplete={autoComplete ?? 'off'}
                onSubmit={formCtx.handleSubmit((values: any) => onSubmit(values, formCtx))}
            >
                {children}
            </form>
        </FormProvider>
  )
}

export default Form
