import { useContextSelector } from 'use-context-selector'
import { useMemo } from 'react'
import { OfflineCache, type OfflineCacheType } from './index'

const useOfflineCache = (): OfflineCacheType => {
  const addVote = useContextSelector(OfflineCache, (state) => state.addVote)
  const votesToSync = useContextSelector(OfflineCache, (state) => state.votesToSync)
  return useMemo(() => ({ addVote, votesToSync }), [addVote, votesToSync])
}

export default useOfflineCache
