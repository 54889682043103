import {
  useCallback, useEffect, useRef, useState
} from 'react'

const useWindowResizeListener = (): { width: number, height: number } => {
  const timeoutRef = useRef<NodeJS.Timeout | null>(null)
  const [dimensions, setDimensions] = useState({
    width: window.innerWidth,
    height: window.innerHeight
  })

  const handleResize = useCallback(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current)
    }
    timeoutRef.current = setTimeout(() => {
      setDimensions({
        width: window.innerWidth,
        height: window.innerHeight
      })
    }, 250)
  }, [])

  useEffect(() => {
    window.addEventListener('resize', handleResize)
    return () => { window.removeEventListener('resize', handleResize) }
  }, [handleResize])

  return dimensions
}

export default useWindowResizeListener
