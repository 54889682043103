import { Network } from '@capacitor/network'
import { useEffect, useState } from 'react'

const useNetworkStatus = (): 'wifi' | 'cellular' | 'none' | 'unknown' => {
  const [status, setStatus] = useState<'wifi' | 'cellular' | 'none' | 'unknown'>('cellular')

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  useEffect(() => {
    Network.addListener('networkStatusChange', s => {
      setStatus(s.connectionType)
    })

    return Network.removeAllListeners
  }, [])

  return status
}

export default useNetworkStatus
