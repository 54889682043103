import { Capacitor } from '@capacitor/core'
import { IonButton, IonContent, IonFooter, IonIcon, IonModal } from '@ionic/react'
import clsx from 'clsx'
import { closeOutline } from 'ionicons/icons'
import type { ReactNode } from 'react'
import { forwardRef } from 'react'

import isIOS from '../../lib/isIOS'
import Header from '../Header'

interface ModalI {
  modalTitle: string | JSX.Element | null
  onClose?: () => void
  footer?: ReactNode
  size?: 'fullscreen' | 'large' | 'alert' | 'default' | 'onboarding'
}

type ModalProps = React.ComponentProps<typeof IonModal> & ModalI

const Modal: React.FC<ModalProps> = forwardRef(({ onClose, modalTitle, footer, size = 'fullscreen', children, ...modalProps }, ref) => (
    <IonModal
        {...modalProps}
        ref={ref}
        className={clsx(`${modalProps.className} modal--${size}`, { 'modal--safari-fix': isIOS && !Capacitor.isNativePlatform() })}
    >
        <Header
            isModal
            buttonLeft='none'
            title={modalTitle}
        >
            {onClose && (
                <IonButton
                    onClick={onClose}
                    size='large'
                >
                    <IonIcon icon={closeOutline} />
                </IonButton>
            )}
        </Header>

        {size !== 'alert'
          ? (
            <IonContent
                fullscreen
            >
                { children }
            </IonContent>
            )
          : (
            <div
                className='inner-content'
            >
                {children}
            </div>
            )}

        {footer && (
            <IonFooter>
                { footer }
            </IonFooter>
        )}
    </IonModal>
))

Modal.displayName = 'Modal'

export default Modal
