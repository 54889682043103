import { IonBadge, IonButton, IonIcon, IonSpinner } from '@ionic/react'
import clsx from 'clsx'
import { forwardRef } from 'react'

import './style.scss'

export interface ButtonI {
  icon?: string
  iconSrc?: string
  loading?: boolean
  round?: boolean
  badge?: string | number
  buttonSize?: 'small' | 'default' | 'large'
}

export type ButtonProps = React.ComponentProps<typeof IonButton> & ButtonI

const Button: React.FC<ButtonProps> = forwardRef<HTMLIonButtonElement, ButtonProps>(({
  children,
  loading = false,
  disabled,
  className,
  icon,
  iconSrc,
  round,
  badge,
  size,
  buttonSize,
  ...ionButtonProps
}, ref) => {
  return (
        <IonButton
            ref={ref}
            {...ionButtonProps}
            size={buttonSize}
            className={clsx(className, 'jmt-button', { 'jmt-button--has-content': !!children, 'jmt-button--round': round })}
            disabled={disabled ?? loading}
        >
            {loading
              ? (
                <IonSpinner
                    className='jmt-button__loading-spinner'
                />
                )
              : icon
                ? (
                <IonIcon
                    icon={icon}
                    size={size}
                />
                  )
                : iconSrc
                  ? (
                <IonIcon
                    src={iconSrc}
                    size={size}
                />
                    )
                  : null}
            <div
                className='jmt-button__label'
            >
                {children}
            </div>
            {badge && (
                <IonBadge
                    className='jmt-button__badge'
                >
                    {badge}
                </IonBadge>
            )}
        </IonButton>
  )
})

Button.displayName = 'Button'

export default Button
