import { Capacitor } from '@capacitor/core'
import { IonLabel, IonRange } from '@ionic/react'
import clsx from 'clsx'
import { cropOutline } from 'ionicons/icons'
import { useCallback, useEffect, useRef, useState } from 'react'
import AvatarEditor from 'react-avatar-editor'
import { useTranslation } from 'react-i18next'

import { useUploadImageMutation } from '../../../lib/apollo/types'
import useWindowResizeListener from '../../../lib/hooks/useWindowResizeListener'
import Button from '../../Button'
import Modal from '../../Modal'
import PinchAndPan from '../../PinchAndPan'

import './style.scss'

interface CropModalProps {
  size: { width: number, height: number }
  imageUrl: string
  isOpen: boolean
  avatarWrapperClassName?: string
  onClose: () => any
  onSave: (uploadResponse: string) => any
}

const getImageInGivenSize = (image: string, size: { width: number, height: number }, resolve: (base64: string) => any) => {
  const { width, height } = size

  const canvas: HTMLCanvasElement = document.createElement('canvas')
  canvas.width = width
  canvas.height = height
  const img = new Image()

  img.onload = () => {
    canvas.getContext('2d')?.drawImage(img, 0, 0, width, height)
    resolve(canvas.toDataURL())
  }

  img.src = image
}

const CropModal: React.FC<CropModalProps> = ({ size, imageUrl, isOpen, onClose, onSave, avatarWrapperClassName = '' }) => {
  const { t } = useTranslation()
  const a = useWindowResizeListener()

  const wrapperRef = useRef<HTMLDivElement>(null)
  const editor = useRef<AvatarEditor>(null)
  const [displaySize, setDisplaySize] = useState<{ width: number, height: number }>({ width: 0, height: 0 })
  const [imageSize, setImageSize] = useState<{ width: number, height: number }>({ width: 0, height: 0 })
  const [scale, setScale] = useState<number | undefined>()
  const [loading, setLoading] = useState<boolean>(false)
  const [scaleValues, setScaleValues] = useState<{ min: number, max: number }>({ min: 1, max: 1 })
  const [uploadImageMutation] = useUploadImageMutation()

  const readAndSetWidth = useCallback(() => {
    if (wrapperRef.current) {
      const aspect = size.width / size.height
      // height - sub range height if not native
      const modalHeight = (a.height * 0.8) - (Capacitor.isNativePlatform() ? 0 : 95)

      if (((wrapperRef.current.clientWidth - 50) * (1 / aspect)) > modalHeight - 50) {
        setDisplaySize({
          width: (modalHeight - 50) * aspect,
          height: modalHeight - 50
        })
      } else {
        setDisplaySize({
          width: wrapperRef.current.clientWidth - 50,
          height: (wrapperRef.current.clientWidth - 50) * (1 / aspect)
        })
      }
    }
  }, [a.height, size.height, size.width])

  const onSaveButtonClick = useCallback(() => {
    setLoading(true)
    window.requestAnimationFrame(() => {
      if (editor.current) {
        getImageInGivenSize(editor.current.getImage().toDataURL(), size, async (base64Url) => {
          const response = await uploadImageMutation({
            variables: {
              input: base64Url
            }
          })
          if (response.data?.uploadImage) {
            onSave(response.data.uploadImage)
          }
          setLoading(false)
        })
      }
    })
  }, [onSave, size, uploadImageMutation])

  useEffect(() => {
    const img = new Image()
    img.onload = () => {
      const { width, height } = img
      setImageSize({ width, height })
    }
    img.src = imageUrl
  }, [imageUrl])

  useEffect(() => {
    if (!imageSize.width || !displaySize.width) {
      return
    }

    const minScale = 1
    const maxScale = Math.min(imageSize.width / size.width, imageSize.height / size.height)

    setScale(minScale)
    setScaleValues({ min: minScale, max: maxScale })
  }, [imageSize, displaySize, size.width, size.height])

  const onZoom = useCallback((factor: number) => {
    setScale(s => {
      if (!s || !factor) {
        return s
      }

      const newScale = s + (factor / 100)
      if (newScale < scaleValues.min) {
        return scaleValues.min
      }
      if (newScale > scaleValues.max) {
        return scaleValues.max
      }

      return newScale
    })
  }, [setScale, scaleValues])

  return (
        <Modal
            isOpen={isOpen}
            onDidPresent={() => {
              readAndSetWidth()
            }}
            modalTitle='Bild aufnehmen'
            onClose={onClose}
            size='fullscreen'
            className=''
        >
            <>
                <PinchAndPan
                    zoom={onZoom}
                >
                    <div
                        className={clsx('avatar-wrapper', avatarWrapperClassName)}
                        ref={wrapperRef}
                    >
                        {!!displaySize.width && !!scale && (
                            <AvatarEditor
                                ref={editor}
                                image={imageUrl}
                                width={displaySize.width}
                                height={displaySize.height}
                                scale={scale}
                            />
                        )}
                    </div>
                </PinchAndPan>
                <div>
                    <IonLabel>
                        {t('onboarding.zoom')}
                    </IonLabel>
                    <IonRange
                        min={scaleValues.min}
                        max={scaleValues.max}
                        step={0.1}
                        value={scale}
                        onIonInput={(e) => {
                          setScale(e.detail.value as number)
                        }}
                    />
                    <Button
                        icon={cropOutline}
                        onClick={onSaveButtonClick}
                        loading={loading}
                    >
                        {t('buttons.crop')}
                    </Button>
                </div>
            </>
        </Modal>
  )
}

export default CropModal
