import { IonSkeletonText } from '@ionic/react'
import clsx from 'clsx'
import { arrowUndo } from 'ionicons/icons'
import { useTalentCardContext } from '../_Core/Context'
import TalentCardPaper from '../_Core/TalentCardPaper'
import Button from '../../Button'
import TalentID from '../../TalentID'

import './style.scss'

interface TalentCardMediumBackProps {
  visible: boolean
  onFlipCardClick: () => void
  className?: string
}

const TalentCardMediumBack: React.FC<TalentCardMediumBackProps> = ({ visible, onFlipCardClick, className }) => {
  const { certificate } = useTalentCardContext()

  return (
        <TalentCardPaper
            className={clsx('talentcard-medium-back', { 'talentcard-medium-back--visible': visible, className })}
            isBackground
        >
            <Button
                className='talentcard-medium-back__switch-btn'
                fill='clear'
                onClick={(e) => {
                  e.stopPropagation()
                  onFlipCardClick()
                }}
                icon={arrowUndo}
                size='small'
            />

            <div
                className='talentcard-medium-back__talentid-wrapper'
            >
                <div
                    className='talentcard-medium-back__profile-link'
                >
                    <h3
                        className='ion-text-center'
                    >
                        {certificate?.caption ?? (
                            <IonSkeletonText
                                style={{ width: 200 }}
                            />
                        )}
                    </h3>
                </div>

                <TalentID
                    values={certificate?.vote}
                    id='talent-id-detail'
                    interactive
                    talentClass={certificate?.talentClass === 2 ? '2' : '1'}
                />
            </div>
        </TalentCardPaper>
  )
}

export default TalentCardMediumBack
