import { ApolloError, useApolloClient } from '@apollo/client'
import type { MutationHookOptions } from '@apollo/client'
import { Preferences } from '@capacitor/preferences'
import { useCallback } from 'react'

import {
  type AuthenticationPinInput,
  type SigninWithCodeMutation,
  type SigninWithCodeMutationVariables,
  type SigninWithPinMutation,
  type SigninWithPinMutationVariables,
  useSigninWithCodeMutation,
  useSigninWithPinMutation,
  useWhoamiQuery,
  WhoamiDocument
  , type Authentication
} from '../../lib/apollo/types'
import STORAGE_KEYS from '../../lib/storageKeys'
import { SSO_ACTIVE, SSO_LOGOUT_URL } from '../../lib/apollo/config'

export const useSignIn = (baseOptions?: MutationHookOptions<SigninWithCodeMutation, SigninWithCodeMutationVariables>): (code: string) => Promise<void> => {
  const client = useApolloClient()
  const [signin] = useSigninWithCodeMutation(baseOptions)

  return useCallback(async (code: string): Promise<void> => {
    const response = await signin({ variables: { input: { code } } })

    if (response.data?.signinWithCode) {
      await Preferences.set({
        key: STORAGE_KEYS.API.LOGIN_TOKEN,
        value: response.data?.signinWithCode.token
      })

      client.writeQuery({
        query: WhoamiDocument,
        data: { whoami: response.data?.signinWithCode }
      })
    } else if (baseOptions?.onError) {
      baseOptions.onError(new ApolloError({}))
    }
  }, [baseOptions, client, signin])
}

export const useSignInWithPin = (baseOptions?: MutationHookOptions<SigninWithPinMutation, SigninWithPinMutationVariables>): [(input: AuthenticationPinInput) => Promise<void>, boolean] => {
  const client = useApolloClient()
  const [signin, { loading }] = useSigninWithPinMutation(baseOptions)
  const signIn = useCallback(async (input: AuthenticationPinInput): Promise<void> => {
    const response = await signin({ variables: { input } })

    if (response.data?.signinWithPin) {
      await Preferences.set({
        key: STORAGE_KEYS.API.LOGIN_TOKEN,
        value: response.data?.signinWithPin.token
      })

      client.writeQuery({
        query: WhoamiDocument,
        data: { whoami: response.data?.signinWithPin }
      })
    } else if (baseOptions?.onError) {
      baseOptions.onError(new ApolloError({}))
    }
  }, [baseOptions, client, signin])
  return [signIn, loading]
}

export const signOut: () => Promise<void> = async () => {
  await Preferences.remove({ key: STORAGE_KEYS.API.LOGIN_TOKEN })

  if (SSO_ACTIVE) {
    window.location.href = SSO_LOGOUT_URL
  } else {
    setTimeout(() => {
      window.location.href = '/login'
    }, 500)
  }
}

export const useUser = (): Authentication | undefined => {
  const { data } = useWhoamiQuery(
    {
      fetchPolicy: 'cache-and-network'
    }
  )

  if (data?.whoami?.token) {
    Preferences.set({
      key: STORAGE_KEYS.API.LOGIN_TOKEN,
      value: data.whoami.token
    })
  }

  return data?.whoami
}
