import clsx from 'clsx'
import TalentCardPaperSvg from '../TalentCardPaperSvg'

import './style.scss'
import { type PropsWithChildren } from 'react'
import { useTalentCardContext } from '../Context'
import { CARD_HEADER } from '../../../../lib/apollo/config'

interface TalentCardPaperProps {
  className?: string
  isBackground?: boolean
  showLogo?: boolean
}

const TalentCardPaper = ({ isBackground, className, children }: PropsWithChildren<TalentCardPaperProps>) => {
  const { schoolImg } = useTalentCardContext()

  return (
        <div
            className={clsx(className, 'talentcard-paper', { 'talentcard-paper--is-background': isBackground })}
        >
            <TalentCardPaperSvg
                isBackground={isBackground}
            />

            <div
                tabIndex={-1}
                role='button'
                className='talentcard-paper__overlay'
            >
                {children}
            </div>

            {!isBackground && (
                <div
                    className='talentcard-paper__logo'
                >
                    {CARD_HEADER ?? (
                        <img src='/assets/logo_with_text_white.svg' />
                    )}
                </div>
            )}

            {schoolImg && isBackground && (
                <img
                    className='talentcard-paper__logo__school-img'
                    alt='Logo'
                    src={schoolImg}
                 />
            )}
        </div>
  )
}

export default TalentCardPaper
