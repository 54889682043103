import './style.scss'
import type { IonButton } from '@ionic/react'
import { IonList, IonPopover } from '@ionic/react'
import { closeOutline, ellipsisVertical } from 'ionicons/icons'
import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'

import Button from '../Button'

import ContextMenuItem from './ContextMenuItem/ContentMenuItem'
import { ContextMenuContext } from './context'

import IS_IOS from '../../lib/isIOS'

const ContextMenu: React.FC<{ icon?: string, label?: string } & React.ComponentProps<typeof IonButton>> = ({ label, icon = ellipsisVertical, children, ...buttonProps }) => {
  const { t } = useTranslation()
  const [open, setOpen] = useState<React.MouseEvent<HTMLIonButtonElement, MouseEvent> | false>(false)

  const closeContextMenu = useCallback((cb?: () => void) => {
    setOpen(false)
    if (typeof cb === 'function') {
      cb()
    }
  }, [setOpen])

  return (
        <>
            <Button
                {...buttonProps}
                fill='clear'
                color='secondary'
                icon={icon}
                onClick={(e) => {
                  e.stopPropagation()
                  e.preventDefault()
                  if (!buttonProps.routerLink) {
                    setOpen(e)
                  }
                }}
            >
                {label}
            </Button>
            <IonPopover
                alignment={IS_IOS ? undefined : 'end'}
                className='context-menu-modal'
                isOpen={!!open}
                event={open}
                showBackdrop
                onDidDismiss={() => { closeContextMenu() }}
            >
                <IonList>
                    <ContextMenuContext.Provider
                        value={{ closeContextMenu }}
                    >
                        { children }
                    </ContextMenuContext.Provider>
                </IonList>
                <ContextMenuItem
                    label={t('buttons.close')}
                    icon={closeOutline}
                    onClick={closeContextMenu}
                    lines='none'
                    underlined
                />
            </IonPopover>
        </>
  )
}

export default ContextMenu
