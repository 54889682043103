import { IonText } from '@ionic/react'
import './style.scss'
import { type PropsWithChildren } from 'react'
import clsx from 'clsx'

interface ButtonsProps {
  title?: string | null
  subtitle?: string | null
  className?: string
}

const Buttons: React.FC<ButtonsProps & PropsWithChildren> = ({ title, subtitle, children, className }) => (
    <div
        className={clsx('buttons', className)}
    >
        <div
            className='buttons__wrapper'
        >
            { title && (
                <h2
                    className='ion-margin-bottom buttons__title'
                >
                    { title }
                </h2>
            )}
            { subtitle && (
                <div className='n2br'>
                    <IonText
                        className='ion-text-justify buttons__subtitle font-s-regular'
                        color='medium'
                    >
                        <span>
                            { subtitle }
                        </span>
                    </IonText>
                </div>
            )}
            { children }
        </div>
    </div>
)

export default Buttons
