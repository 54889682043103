import { IonPage } from '@ionic/react'
import { type PropsWithChildren } from 'react'
import clsx from 'clsx'

interface PageProps extends PropsWithChildren {
  trackingTitle?: string
}

const Page: React.FC<PageProps & React.ComponentProps<typeof IonPage>> = ({
  children,
  trackingTitle,
  ...ionPageProps
}) => {
  // const { t } = useTranslation()

  return (
        <IonPage
            {...ionPageProps}
            className={clsx(ionPageProps.className, 'jmt-page')}
        >
            {children}
        </IonPage>
  )
}

export default Page
