import { createContext } from 'use-context-selector'
import { type PropsWithChildren, useEffect, useState } from 'react'
import { type ConnectionStatus, Network as CapNetwork } from '@capacitor/network'
import { useApolloClient } from '@apollo/client'

export interface NetworkType {
  isOnline: boolean
}
export const Network = createContext<NetworkType>({
  isOnline: false
})

export const checkConnectionStatus = (status: ConnectionStatus) => status.connected && (status.connectionType === 'wifi' || status.connectionType === 'cellular')

export const NetworkProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [isOnline, setIsOnline] = useState<boolean>(true)

  const client = useApolloClient()

  useEffect(() => {
    const checkNetwork = async () => {
      const status = await CapNetwork.getStatus()
      setIsOnline(checkConnectionStatus(status))
    }
    checkNetwork()
    CapNetwork.addListener('networkStatusChange', status => {
      setIsOnline(checkConnectionStatus(status))
    })

    return () => {
      CapNetwork.removeAllListeners()
    }
  }, [client, setIsOnline])

  return (
        <Network.Provider
            value={{ isOnline }}
        >
            {children}
        </Network.Provider>
  )
}
