import {
  type Camp, CampDocument,
  DispositionStatus,
  useCreateDispositionMutation,
  useDeleteDispositionMutation
} from '../../lib/apollo/types'
import Button from '../Button'
import { thumbsDown, thumbsUp } from 'ionicons/icons'
import { useTranslation } from 'react-i18next'
import useToast from '../../providers/Toast/hooks'
import { useUser } from '../../providers/Auth/hooks'

interface CampDispositionButtonProps {
  camp: Camp
}

const CampDispositionButton: React.FC<CampDispositionButtonProps> = ({ camp }) => {
  const { t } = useTranslation()

  const me = useUser()

  const [present] = useToast()
  const [createDisposition] = useCreateDispositionMutation({
    refetchQueries: [{
      query: CampDocument,
      variables: {
        campId: camp.id
      }
    }]
  })

  const [deleteDisposition] = useDeleteDispositionMutation({
    refetchQueries: [{
      query: CampDocument,
      variables: {
        campId: camp.id
      }
    }]
  })

  if (!camp.openDispositions || camp.trainers?.some(t => t.id === me?.user.sub)) return null

  const self = camp.dispositions?.find(d => d.trainer.email)

  if (self?.status === DispositionStatus.Available) {
    return (
            <div className='display-flex ion-justify-content-center'>
                <Button
                    icon={thumbsDown}
                    onClick={async (e) => {
                      e.preventDefault()
                      e.stopPropagation()
                      await deleteDisposition({
                        variables: {
                          id: +self.id,
                          campId: camp.id
                        }
                      })
                      present(t('camps.disposition.toast_unavailable'), 'success')
                    }}
                >
                    {t('camps.disposition.button_unavailable')}
                </Button>
            </div>
    )
  }

  return (
        <div className='display-flex ion-justify-content-center'>
            <Button
                icon={thumbsUp}
                onClick={async (e) => {
                  e.preventDefault()
                  e.stopPropagation()
                  await createDisposition({
                    variables: {
                      input: {
                        campId: camp.id,
                        status: DispositionStatus.Available
                      }
                    }
                  })
                  present(t('camps.disposition.toast_available'), 'success')
                }}
            >
                {t('camps.disposition.button_available')}
            </Button>
        </div>
  )
}

export default CampDispositionButton
