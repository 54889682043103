import './style.scss'
import { type Camp, useNotificationQuery } from '../../lib/apollo/types'
import CampCardSmall from '../Camps/CampCardSmall'
import { useTranslation } from 'react-i18next'
import { IonCard, IonCardContent, useIonRouter } from '@ionic/react'
import useOfflineCache from '../../providers/OfflineCache/hooks'
import Slider from '../Slider'
import { useMemo } from 'react'

const Notifications: React.FC = () => {
  const { t } = useTranslation()
  const { data } = useNotificationQuery({
    fetchPolicy: 'cache-and-network'
  })
  const { push } = useIonRouter()
  const { votesToSync } = useOfflineCache()

  const notifications = useMemo(() => {
    const notifications: Record<string, Camp & { action: string }> = {}
    data?.notification.missingVotes.forEach((camp: Camp) => {
      notifications[camp.id] = {
        ...camp,
        action: t('camp.missing.vote')
      }
    })

    data?.notification.missingImages.forEach((camp: Camp) => {
      if (notifications[camp.id]) {
        notifications[camp.id] = {
          ...camp,
          action: `${notifications[camp.id].action} ${t('camp.missing.images')}`
        }
      } else {
        notifications[camp.id] = {
          ...camp,
          action: t('camp.missing.images')
        }
      }
    })

    return notifications
  }, [data?.notification.missingImages, data?.notification.missingVotes, t])

  if ((!data?.notification || data?.notification.missingVotes.length === 0) && votesToSync === 0) {
    return null
  }

  return (
        <Slider
            title={t('notification.todo.title') }
            freeMode
            itemWidth={215}
        >
            {votesToSync > 0 && (
                <IonCard className='offline-votes-card'>
                    <IonCardContent className='notification'>
                        <div className='notification__text'>{t('notification.offline.votes.text', { count: votesToSync })}</div>
                    </IonCardContent>
                </IonCard>
            )}
            {Object.entries(notifications).map(([id, camp]) => (
                <CampCardSmall
                    camp={camp}
                    key={camp.id}
                    action={camp.action}
                    onClick={() => { push(`/camps/${camp.id}`) }}
                />
            ))}
        </Slider>
  )
}

export default Notifications
