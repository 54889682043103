import { useTranslation } from 'react-i18next'
import Content from '../../../components/Content'
import Certificate from '../../../components/Certificate'
import { useMemo } from 'react'
import useRating from '../../../lib/hooks/useRating'
import { useCampQuery, useTalentQuery, type Certificate as GqlCertificate } from '../../../lib/apollo/types'
import { type RouteComponentProps } from 'react-router'
import Header from '../../../components/Header'
import Page from '../../../components/Page'
import { CLUB_SHORT, SCHOOL_IMAGE } from '../../../lib/apollo/config'

interface TalentCertificatePageProps
  extends RouteComponentProps<{
    id: string
    talentId: string
  }> {
}
const TalentCertificatePage: React.FC<TalentCertificatePageProps> = ({ match }) => {
  const { t } = useTranslation()

  const { rating } = useRating(match.params.talentId)

  const { data: camp } = useCampQuery({
    variables: {
      campId: match.params.id
    },
    skip: !match.params.id
  })

  const { data: talent } = useTalentQuery({
    variables: {
      id: match.params.talentId
    },
    skip: !match.params.talentId
  })

  const certificateProps: GqlCertificate | undefined = useMemo(() => {
    if (!talent?.talent || !camp?.camp || !talent.talent.birthday) return undefined

    return {
      id: talent.talent.id,
      participantId: match.params.talentId,
      caption: `${talent.talent.firstname} ${talent.talent.lastname}`,
      birthday: talent.talent.birthday,
      image: talent.talent.image,
      vote: rating,
      camp: camp.camp,
      firstName: talent.talent.firstname,
      lastName: talent.talent.lastname,
      talentClass: talent.talent.talentClass ?? 1
    }
  }, [camp?.camp, match.params.talentId, rating, talent?.talent])

  return (
        <Page className='camp-preview'>
            <Header title={t('title.certificate')}></Header>
            <Content className='camp-wrapper'>
                <div className='certificates'>
                    {certificateProps && (
                        <Certificate
                            club={CLUB_SHORT}
                            certificate={{
                              ...certificateProps
                            }}
                            header={{
                              intro: 'Bei uns bist du der Star'
                            }}
                            footer={{
                              text: t('certificate.footer'),
                              img: '/assets/football-badge-svgrepo-com.svg'
                            }}
                            clubImg='/assets/icon/logo.svg'
                            schoolImg={SCHOOL_IMAGE}
                        />
                    )}
                </div>
            </Content>
        </Page>
  )
}

export default TalentCertificatePage
