import { type PropsWithChildren, useCallback, useEffect, useRef, useState } from 'react'
import { createContext } from 'use-context-selector'

import { useApolloNetworkStatus } from '../../lib/apollo'

import type { GlobalLoadingType } from './types'

const LOADING_DELAY_TIME = 350

export const GlobalLoading = createContext<GlobalLoadingType>({
  visible: false,
  show: () => {
  },
  dismiss: () => {
  }
})

export const GlobalLoadingProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [externalCount, setExternalCount] = useState<number>(0)

  const timeoutIdRef = useRef<NodeJS.Timeout | undefined>(undefined)
  const [visible, setVisible] = useState<boolean>(false)

  const status = useApolloNetworkStatus()

  const show = useCallback(() => {
    setExternalCount((prevState => prevState + 1))
  }, [])

  const dismiss = useCallback(() => {
    setExternalCount((prevState => {
      if (prevState <= 1) {
        return 0
      }
      return prevState - 1
    }))
  }, [])

  useEffect(() => {
    const total = externalCount + status.numPendingQueries + status.numPendingMutations

    if (total > 0 && !visible) {
      if (!timeoutIdRef.current) {
        timeoutIdRef.current = setTimeout(() => {
          timeoutIdRef.current = undefined
          setVisible(true)
        }, LOADING_DELAY_TIME)
      }
    }

    if (total === 0 && visible) {
      setVisible(false)
    }

    if (total === 0 && timeoutIdRef.current) {
      clearTimeout(timeoutIdRef.current)
      timeoutIdRef.current = undefined
    }
  }, [externalCount, status.numPendingQueries, status.numPendingMutations, setVisible, visible])

  return (
        <GlobalLoading.Provider
            value={{ visible, show, dismiss }}
        >
            {children}
        </GlobalLoading.Provider>
  )
}

export default GlobalLoading
