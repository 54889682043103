import {
  IonHeader,
  IonToolbar,
  IonButtons,
  IonMenuButton,
  IonProgressBar,
  IonBackButton, IonTitle, IonSkeletonText, useIonRouter
} from '@ionic/react'
import clsx from 'clsx'
import { useTranslation } from 'react-i18next'

import { ENV_NAME } from '../../lib/apollo/config'
import IS_IOS from '../../lib/isIOS'
import { useLoadingIndicator } from '../../providers/GlobalLoading/hooks'

import './style.scss'
import { useUser } from '../../providers/Auth/hooks'

interface HeaderProps {
  title: string | JSX.Element | null
  buttonLeft?: 'back' | 'menu' | 'none'
  isModal?: boolean
  mainTitle?: boolean
}

const Header: React.FC<HeaderProps & Omit<React.ComponentProps<typeof IonHeader>, 'title'>> = ({ mainTitle = false, isModal, title, buttonLeft = 'back', mode, children, ...headerProps }) => {
  const { visible } = useLoadingIndicator()
  const { t } = useTranslation()

  const { routeInfo } = useIonRouter()

  const user = useUser()

  const defaultHref =
        user?.user.roles.includes('trainer')
          ? routeInfo.pathname === '/dashboard' ? undefined : '/dashboard'
          : routeInfo.pathname === '/certificate' ? undefined : '/certificate'

  return (
        <IonHeader
            {...headerProps}
            className={clsx(headerProps.className, 'header', { 'header--modal': isModal })}
        >
            {ENV_NAME === 'sandbox' && (
                <div
                    className='ribbon'
                >
                Demo
                </div>
            )}
            <IonToolbar
                className={`header__toolbar header__toolbar--${ENV_NAME}`}
                color='primary'
            >
                <IonButtons
                    className={clsx('header__btn', { 'header__btn--hide-desktop': buttonLeft === 'menu' })}
                    slot='start'
                >
                    { buttonLeft === 'back' && (
                        <IonBackButton
                            defaultHref={defaultHref}
                            text={IS_IOS ? t('buttons.back') : undefined}
                        />
                    )}

                    { buttonLeft === 'menu' && <IonMenuButton /> }
                </IonButtons>

                <IonButtons
                    className='header__btn'
                    collapse
                    slot='end'
                >
                    { children }
                </IonButtons>
                <IonTitle
                    className={clsx('ion-text-center', { 'header__title--main': mainTitle })}
                >
                    { title ?? (
                        <IonSkeletonText
                            className='header__skeleton'
                        />
                    ) }
                </IonTitle>
                {visible && (
                    <IonProgressBar
                        type='indeterminate'
                        color='secondary'
                    />
                )}
            </IonToolbar>
        </IonHeader>
  )
}

export default Header
