import { useVotesQuery } from '../apollo/types'
import { useEffect, useState } from 'react'

const useRating = (participantId: string) => {
  const { data, loading } = useVotesQuery({
    variables: {
      talentId: participantId
    },
    skip: !participantId
  })
  const [rating, setRating] = useState<number[]>([0, 0, 0, 0, 0, 0, 0, 0])
  const [loaded, setLoaded] = useState(false)

  const [isTalent, setIsTalent] = useState(false)

  useEffect(() => {
    setLoaded(false)
    if (loading) return
    setIsTalent(false)

    const values: number[] = [0, 0, 0, 0, 0, 0, 0, 0]
    for (let i = 0; i < 8; i++) {
      data?.votes.forEach(vote => {
        values[i] = (vote.values[i] ?? 0) + (values[i] ?? 0)

        if (vote.talent) {
          setIsTalent(true)
        }
      })
    }

    const countVotes = data?.votes.length ?? 0

    if (countVotes > 1) {
      for (let i = 0; i < 8; i++) {
        values[i] = Math.round(values[i] / (countVotes > 0 ? countVotes : 1))
      }
    }

    setRating(values)
    setLoaded(true)
  }, [participantId, data, setRating, loading])

  return { rating, loading, loaded, isTalent }
}

export default useRating
