const Levels: React.FC = () => (
    <g
        className='levels'
    >
        <circle
            cx='250'
            cy='250'
            r='62.5'
        />
        <circle
            cx='250'
            cy='250'
            r='125'
        />
        <circle
            cx='250'
            cy='250'
            r='187.5'
        />
        <text
            x='250'
            y='10'
            transform='translate(4, 2)'
        >
            100
        </text>
        <text
            x='250'
            y='72.5'
            transform='translate(4, 2)'
        >
            75
        </text>
        <text
            x='250'
            y='135'
            transform='translate(4, 2)'
        >
            50
        </text>
        <text
            x='250'
            y='197.5'
            transform='translate(4, 2)'
        >
            25
        </text>
    </g>
)

export default Levels
