import './style.scss'
import Page from '../../components/Page'
import Content from '../../components/Content'
import Button from '../../components/Button'
import { useTranslation } from 'react-i18next'
import { useCallback, useEffect, useState } from 'react'
import { useSignInWithPin } from '../../providers/Auth/hooks'
import { IonIcon, useIonRouter } from '@ionic/react'
import Form from '../../components/Form'
import FormTextField from '../../components/Form/FormTextField'
import Buttons from '../../components/Buttons'
import useToast from '../../providers/Toast/hooks'
import { CLUB_IMAGE, CLUB_SHORT, SSO_ACTIVE, SSO_URL, SSO_REDIRECT_URL } from '../../lib/apollo/config'
import { Capacitor } from '@capacitor/core'
import { useLocation } from 'react-router'
import {
  useGetOneTimePasswordMutation, useSigninWithCodeMutation,
  useSignInWithOneTimePasswordMutation,
  WhoamiDocument
} from '../../lib/apollo/types'
import Modal from '../../components/Modal'
import { Preferences } from '@capacitor/preferences'
import STORAGE_KEYS from '../../lib/storageKeys'
import { useApolloClient } from '@apollo/client'
import { mailUnreadOutline, send } from 'ionicons/icons'

const REDIRECT_ENCODED_URI = encodeURIComponent(SSO_REDIRECT_URL)
const LoginURL = `${SSO_URL}${REDIRECT_ENCODED_URI}`

const Login: React.FC = () => {
  const { t } = useTranslation()
  const { push } = useIonRouter()
  const [present] = useToast()
  const [signIn, loading] = useSignInWithPin()
  const { search } = useLocation()
  const params = new URLSearchParams(search)
  const showPinLogin = CLUB_SHORT === 'default' || params.get('admin') === 'true' || Capacitor.isNativePlatform()

  const signinWithPin = useCallback((input: { email: string, pin: string }) => {
    signIn(input).then(() => {
      push('/dashboard', undefined, 'replace')
    }).catch(() => {
      present(t('login.failed'), 'danger')
    })
  }, [present, push, signIn, t])

  const [getOneTimePassword, { loading: otpLoading }] = useGetOneTimePasswordMutation()
  const [signInWithOTP, { loading: otpLoginLoading }] = useSignInWithOneTimePasswordMutation()
  const [signInWithCode] = useSigninWithCodeMutation()
  const [codeInputOpen, setCodeInputOpen] = useState(false)
  const [email, setEmail] = useState<string>()
  const client = useApolloClient()
  const [token, setToken] = useState<string>()

  useEffect(() => {
    if (params.get('code')) {
      setToken(params.get('code') ?? '')
    }
  }, [])

  useEffect(() => {
    if (token) {
      (async () => {
        const response = await signInWithCode({
          variables: {
            input: {
              code: token ?? params.get('code') ?? ''
            }
          },
          onError: () => {
            present(t('login.codeFailed'), 'danger')
            setTimeout(() => {
              window.location.href = '/login'
            }, 2500)
          }
        })
        if (response.data?.signinWithCode.token) {
          await Preferences.set({
            key: STORAGE_KEYS.API.LOGIN_TOKEN,
            value: response.data?.signinWithCode.token
          })

          client.writeQuery({
            query: WhoamiDocument,
            data: { whoami: response.data?.signinWithCode }
          })

          push('/certificate', undefined, 'replace')
        } else {
          push('/login', undefined, 'replace')
          present(t('login.failed'), 'danger')
        }
      })()
    }
  }, [token, signInWithCode])

  const requestOTP = useCallback(async ({ email }: { email: string }) => {
    const emailSent = await getOneTimePassword({
      variables: {
        input: {
          email
        }
      }
    })

    if (emailSent.data?.getOneTimePassword) {
      setEmail(email)
      setCodeInputOpen(true)
    } else {
      present(t('login.otpFailed'), 'danger')
    }
  }, [])

  const signInWithOneTimeCode = useCallback(async ({ code }: { code: string }) => {
    const response = await signInWithOTP({
      variables: {
        input: {
          email: email ?? '',
          pin: code
        }
      },
      onError: () => {
        present(t('login.codeFailed'), 'danger')
      }
    })

    if (response.data?.signInWithOneTimePassword.token) {
      await Preferences.set({
        key: STORAGE_KEYS.API.LOGIN_TOKEN,
        value: response.data?.signInWithOneTimePassword.token
      })

      client.writeQuery({
        query: WhoamiDocument,
        data: { whoami: response.data?.signInWithOneTimePassword }
      })

      push('/certificate', undefined, 'replace')
    } else {
      present(t('login.failed'), 'danger')
    }
  }, [email])

  return (
        <Page>
            <Content>
                <div>
                    <div className="login">
                        <div className="login__logo">
                            <img src={CLUB_IMAGE} alt='Join My Talent PRO'/>
                        </div>
                        <div className="login__form">
                            {showPinLogin
                              ? (
                                <Form onSubmit={signinWithPin}>
                                    <FormTextField label='E-Mail' name='email' type='email' required/>
                                    <FormTextField label='Pin' name='pin' type='password' required/>
                                    <Buttons>
                                        <Button
                                            loading={loading}
                                            color='primary'
                                            type='submit'
                                        >
                                            {t('login.loginWithPin')}
                                        </Button>
                                    </Buttons>
                                </Form>
                                )
                              : SSO_ACTIVE
                                ? (
                                    <Buttons
                                      title={t('login.loginWithSSO')}
                                      subtitle={t('login.loginWithSSO_description')}
                                    >
                                      <Button
                                          loading={!!token}
                                          color='primary'
                                          onClick={async () => {
                                            window.location.href = LoginURL
                                          }}
                                      >
                                        {t('login.loginWithSSO')}
                                      </Button>
                                  </Buttons>
                                  )
                                : (
                                  <>
                                      <Form onSubmit={requestOTP}>
                                          <FormTextField label='E-Mail' name='email' type='email' required/>
                                          <Buttons>
                                              <Button
                                                  loading={otpLoading}
                                                  color='primary'
                                                  type='submit'
                                                  icon={send}
                                              >
                                                  {t('login.sendOnetimePassword')}
                                              </Button>
                                          </Buttons>
                                      </Form>
                                      <Modal
                                          size='alert'
                                          isOpen={codeInputOpen}
                                          onDidDismiss={() => { setCodeInputOpen(false) }}
                                          onClose={() => { setCodeInputOpen(false) }}
                                          modalTitle={t('login.enterCode')}
                                      >
                                          <Form onSubmit={signInWithOneTimeCode}>
                                              <div className="ion-justify-content-center display-flex login__form__icon">
                                                <IonIcon icon={mailUnreadOutline} size='large' color='primary'/>
                                              </div>
                                              <div className="ion-padding">
                                                  {t('login.enterCodeDescription')}
                                              </div>
                                              <div style={{ maxWidth: '200px', margin: '0 auto' }}>
                                                  <FormTextField label='Code' name='code' type='number' required/>
                                              </div>
                                              <Buttons>
                                                  <Button
                                                      loading={otpLoginLoading}
                                                      color='primary'
                                                      type='submit'
                                                  >
                                                      {t('login.submitCode')}
                                                  </Button>
                                              </Buttons>
                                          </Form>
                                      </Modal>
                                  </>
                                  )}
                        </div>
                    </div>
                </div>
            </Content>
        </Page>
  )
}

export default Login
