import './style.scss'

import { type RouteComponentProps } from 'react-router'
import {
  MarkedSpecialTalentsDocument,
  useCampQuery,
  useMarkAsSpecialTalentMutation,
  useTalentQuery
} from '../../lib/apollo/types'
import Page from '../../components/Page'
import Header from '../../components/Header'
import Content from '../../components/Content'
import CertificateTalentCardMedium from '../../components/CertificateTalentCardMedium'
import { useCallback, useMemo } from 'react'
import useRating from '../../lib/hooks/useRating'
import Buttons from '../../components/Buttons'
import Button from '../../components/Button'
import { useUser } from '../../providers/Auth/hooks'
import { useTranslation } from 'react-i18next'
import { useIonAlert } from '@ionic/react'
import { star } from 'ionicons/icons'
import { CLUB_IMAGE, SCHOOL_IMAGE } from '../../lib/apollo/config'

interface TalentPageProps
  extends RouteComponentProps<{
    id: string
    talentId: string
  }> {}

const TalentPage: React.FC<TalentPageProps> = ({ match }) => {
  const { t } = useTranslation()
  const { data } = useCampQuery({
    variables: {
      campId: match.params.id
    },
    skip: !match.params.id
  })

  const { data: talent } = useTalentQuery({
    variables: {
      id: match.params.talentId
    },
    skip: !match.params.talentId
  })

  const user = useUser()

  const { data: camp } = useCampQuery({
    variables: {
      campId: match.params.id
    },
    skip: !match.params.id
  })

  const { rating } = useRating(talent?.talent?.id ?? '')

  const certificateProps = useMemo(() => {
    if (!talent?.talent || !camp?.camp || !talent.talent.birthday) return undefined
    return {
      id: talent.talent.id,
      participantId: match.params.talentId,
      caption: `${talent.talent.firstname} ${talent.talent.lastname}`,
      birthday: talent.talent.birthday,
      image: talent.talent.image ?? '/assets/default_talentcard.png',
      vote: rating,
      camp: camp.camp,
      birthDate: talent.talent.birthday,
      lastName: talent.talent.lastname,
      firstName: talent.talent.firstname,
      talentClass: talent.talent.talentClass ?? 1
    }
  }, [camp?.camp, match.params.talentId, rating, talent?.talent])

  const campDirector = data?.camp?.campDirector?.email === user?.user.email

  const [present] = useIonAlert()
  const [markTalentMutation] = useMarkAsSpecialTalentMutation({
    refetchQueries: [
      {
        query: MarkedSpecialTalentsDocument,
        variables: {
          campId: match.params.id
        }
      }
    ]
  })
  const onMarkTalentClick = useCallback(() => {
    present({
      message: t('markTalent.alert', { name: `${talent?.talent?.firstname} ${talent?.talent?.lastname}` }),
      buttons: [
        // t('buttons.cancel'),
        {
          text: t('buttons.no'),
          role: 'no',
          handler: async () => await markTalentMutation({
            variables: {
              talentId: match.params.talentId,
              campId: match.params.id,
              isTalent: false
            }
          })
        },
        {
          text: t('buttons.yes'),
          role: 'yes',
          handler: async () => await markTalentMutation({
            variables: {
              talentId: match.params.talentId,
              campId: match.params.id,
              isTalent: true
            }
          })
        }
      ]
    })
  }, [markTalentMutation, match.params.id, match.params.talentId, present, t, talent?.talent?.firstname, talent?.talent?.lastname])

  return (
        <Page className='talent'>
            <Header title={talent?.talent?.firstname ? `${talent?.talent?.firstname} ${talent?.talent?.lastname}` : '...'}></Header>
            <Content>
                <div className='display-flex display-flex-column'>
                    {certificateProps && (
                        <CertificateTalentCardMedium
                            certificate={certificateProps}
                            clubImg={CLUB_IMAGE}
                            schoolImg={SCHOOL_IMAGE}
                        />
                    )}
                    {campDirector && (
                        <Buttons
                            title={t('talent.isTalent.title') }
                            subtitle={t('talent.isTalent.description', { name: `${talent?.talent?.firstname} ${talent?.talent?.lastname}` }) }
                        >
                            <Button className='mark-talent-btn' color='primary' icon={star} onClick={onMarkTalentClick}>{t('talent.isTalent.button')}</Button>
                        </Buttons>
                    )}
                    <Buttons>
                        <Button routerLink={`/camps/${match.params.id}/talents/${match.params.talentId}/vote`}>{t('buttons.talentVote')}</Button>
                        <Button color='secondary' routerLink={`/camps/${match.params.id}/talents/${match.params.talentId}/camera`}>{t('buttons.takePicture')}</Button>
                        <Button color='primary' routerLink={`/camps/${match.params.id}/talents/${match.params.talentId}/certificate`}>{t('buttons.previewCertificate')}</Button>
                    </Buttons>
                </div>
            </Content>
        </Page>
  )
}

export default TalentPage
