import { useMemo } from 'react'
import { useContextSelector } from 'use-context-selector'

import { GlobalLoading } from './index'
import type { GlobalLoadingActionType, GlobalLoadingCountType } from './types'

const useLoading = (): GlobalLoadingActionType => {
  const show = useContextSelector(GlobalLoading, (state) => state.show)
  const dismiss = useContextSelector(GlobalLoading, (state) => state.dismiss)
  return useMemo(() => ({ show, dismiss }), [show, dismiss])
}

export const useLoadingIndicator = (): GlobalLoadingCountType => useContextSelector(GlobalLoading, ({ visible }) => ({
  visible
}))

export default useLoading
