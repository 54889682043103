import './style.scss'
import { useCampsQuery } from '../../lib/apollo/types'
import CampCard from './CampCard'
import Slider from '../Slider'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { endOfYear, format, startOfYear } from 'date-fns'
import { IonText } from '@ionic/react'

let today = startOfYear(new Date())
const firstJuly = new Date(2023, 6, 1)
if (today < firstJuly) {
  today = firstJuly
}
const endDate = endOfYear(new Date())

const AllCamps: React.FC = () => {
  const { t } = useTranslation()
  const { data } = useCampsQuery({
    variables: {
      start: format(today, 'yyyy-MM-dd'),
      end: format(endDate, 'yyyy-MM-dd')
    },
    fetchPolicy: 'cache-and-network'
  })

  const campsGroupedByMonth = useMemo(() => {
    const campsGroupedByMonth: Record<string, any[]> = {}
    data?.camps?.forEach(camp => {
      if (!camp.start) return
      const month = new Date(camp.start).getMonth() + 1
      if (!campsGroupedByMonth[month]) {
        campsGroupedByMonth[month] = []
      }
      campsGroupedByMonth[month].push(camp)
    })
    return campsGroupedByMonth
  }, [data?.camps])

  return (
        <>
            <div className='camps__title'>
                <h2>{t('camps.all.title')}</h2>
                <IonText color='medium'>{t('camps.all.description')}</IonText>
            </div>
            {Object.entries(campsGroupedByMonth ?? {}).map(([month, camps]) => (
                <Slider title={t(`animation_label.m.${month}`) } itemWidth={240} freeMode={true} key={month}>
                    {camps.map(camp => (
                        <CampCard key={camp.id} camp={camp}/>
                    ))}
                </Slider>
            ))}
        </>
  )
}

export default AllCamps
