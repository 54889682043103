import clsx from 'clsx'
import { useEffect, useState } from 'react'

import TalentCardMediumBack from './TalentCardMediumBack'
import TalentCardMediumFront from './TalentCardMediumFront'

import { type Certificate } from '../Certificate/certificate'
import './style.scss'
import { TalentCardContext } from './_Core/Context'

interface TalentCardMediumProps {
  certificate: Certificate
  clubImg?: string
  schoolImg?: string
}
const TalentCardMedium = ({ certificate, clubImg, schoolImg }: TalentCardMediumProps) => {
  const [showBack, setShowBack] = useState(false)
  const [renderBack, setRenderBack] = useState(false)
  useEffect(() => {
    setTimeout(() => {
      setRenderBack(() => true)
    }, 500)
  }, [])

  return (
      <TalentCardContext.Provider
          value={{
            certificate,
            clubImg,
            schoolImg
          }}>
        <div
            className={clsx('talentcard-medium')}
        >
            <TalentCardMediumFront
                onFlipCardClick={() => { setShowBack(() => true) }}
                visible={!showBack}
            />
            <TalentCardMediumBack
                onFlipCardClick={() => { setShowBack(() => false) }}
                visible={showBack}
                className={clsx({ 'talentcard-medium--hide': !renderBack })}
            />
        </div>

      </TalentCardContext.Provider>
  )
}

export default TalentCardMedium
