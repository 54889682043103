const getKey = (vote: number) => {
  if (vote < 24) return '5'
  if (vote < 44) return '4'
  if (vote < 51) return '3'
  if (vote < 64) return '3+'
  if (vote < 84) return '2'
  return '1'
}

export default getKey
