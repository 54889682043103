import clsx from 'clsx'
import { useTranslation } from 'react-i18next'

import { ChartType } from '../../lib/apollo/types'

import Pie from './Pie'
import Radar from './Radar'

export interface LayerI {
  id: string
  withHover: boolean
  withGroupLabels: boolean
  values: number[]
  compareValues?: number[]
  animateToValues?: number[][]
  translations?: string[]
  translationKeys?: string[]
  type: ChartType
}

interface LayerProps {
  id: string
  layer: LayerI
  animationIndex?: number
}

const getGClassName = (layer: LayerI) => {
  switch (layer.type) {
    case ChartType.Radar: return 'diagRadar'
    case ChartType.Pie: return 'diagPie l-global'
    default: return ''
  }
}

const Layer: React.FC<LayerProps> = ({ id, layer, animationIndex }) => {
  const { t } = useTranslation()

  return (
        <>
            { layer.withGroupLabels && (

                <g
                    className='sectors'
                >
                    <path
                        transform='translate(250, 250)'
                        d='M252,0A252,252 0 0,0 0,-252L0,0Z'
                    />

                    <text
                        className='sector-0-text'
                    >
                        <textPath
                            textAnchor='middle'
                            href={`#${id}-cp2`}
                            startOffset='18.75%'
                        >
                            {t('i18n.group.0')}
                        </textPath>
                    </text>

                    <path
                        transform='translate(250, 250)'
                        d='M0,252A252,252 0 0,0 252,0L0,0Z'
                    />

                    <text
                        className='sector-1-text'
                    >
                        <textPath
                            textAnchor='middle'
                            href={`#${id}-cp2-lower`}
                            startOffset='43.75%'
                        >
                            {t('i18n.group.1')}
                        </textPath>
                    </text>

                    <path
                        transform='translate(250, 250)'
                        d='M-252,0A252,252 0 0,0 0,252L0,0Z'
                    />

                    <text
                        className='sector-2-text'
                    >
                        <textPath
                            textAnchor='middle'
                            href={`#${id}-cp2-lower`}
                            startOffset='31.25%'
                        >
                            {t('i18n.group.2')}
                        </textPath>
                    </text>

                    <path
                        transform='translate(250, 250)'
                        d='M-0,-252A252,252 0 0,0 -252,0L0,0Z'
                    />

                    <text
                        className='sector-3-text'
                    >
                        <textPath
                            textAnchor='middle'
                            href={`#${id}-cp2`}
                            startOffset='56.25%'
                        >
                            {t('i18n.group.3')}
                        </textPath>
                    </text>
                </g>
            )}

            <g
                className={clsx(getGClassName(layer), { hoverLabels: layer.withHover })}
            >
                { (layer.type === ChartType.Radar && (
                    <Radar
                        id={id}
                        translations={layer.translations ?? []}
                        values={layer.values}
                        compareValues={layer.compareValues}
                        animateToValues={layer.animateToValues}
                        animationIndex={animationIndex}
                    />
                )) ?? null}

                { (layer.type === ChartType.Pie && (
                    <Pie
                        id={id}
                        values={layer.values}
                        compareValues={layer.compareValues}
                    />
                )) ?? null}
            </g>
        </>
  )
}

export default Layer
