import { createContext, useContextSelector } from 'use-context-selector'
import { type Certificate } from '../../../Certificate/certificate'

export interface TalentCardContextType {
  certificate?: Certificate
  onClick?: () => void
  clubImg?: string
  schoolImg?: string
}

export const TalentCardContext = createContext<TalentCardContextType>({})

export const useTalentCardContext = (): TalentCardContextType => useContextSelector(TalentCardContext, ctx => ctx)
