import './JoinJMTModal.scss'
import {
  type Certificate
} from '../../../lib/apollo/types'
import { CLUB_SHORT } from '../../../lib/apollo/config'
import DefaultJoinModal from './Default'

export interface JMTModalProps {
  open: boolean
  close: () => void
  certificate: Certificate
}

const JoinJMTModal: React.FC<JMTModalProps> = ({ open, close, certificate }) => {
  switch (CLUB_SHORT) {
    default: return <DefaultJoinModal open={open} close={close} certificate={certificate} />
  }
}

export default JoinJMTModal
