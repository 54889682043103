import { type Certificate } from './certificate'

const getSignature = (certificate: Certificate): string => {
  const year = certificate.camp.end ? (new Date(certificate.camp.end)).getFullYear().toString() : undefined
  const capture = certificate.camp.caption ?? ''

  return capture + (year ? ` ${year}` : '')
}

export default getSignature
