import type { PropsWithChildren } from 'react'
import { createContext } from 'use-context-selector'
import { useCallback, useEffect, useState } from 'react'
import { Preferences } from '@capacitor/preferences'
import STORAGE_KEYS from '../../lib/storageKeys'
import useNetworkStatus from '../../lib/hooks/useNetworkStatus'
import {
  NotificationDocument,
  SpecialTalentsDocument, TalentDocument, TalentLatestVoteDocument,
  useShortVoteMutation,
  VotesDocument
} from '../../lib/apollo/types'

interface Vote { talentId: string, isTalent: boolean, values: number[], networkId?: string, class: number }

export interface OfflineCacheType {
  addVote: (vote: Vote) => any
  votesToSync: number
}

export const OfflineCache = createContext<OfflineCacheType>({
  addVote: () => {},
  votesToSync: 0
})

const OfflineCacheProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [votesToSync, setVotesToSync] = useState<Vote[]>([])
  const networkStatus = useNetworkStatus()

  const [vote] = useShortVoteMutation()

  useEffect(() => {
    // init state
    Preferences.get({ key: STORAGE_KEYS.CACHE.VOTES }).then(({ value }) => {
      if (value) {
        setVotesToSync(JSON.parse(value))
      }
    })
  }, [])

  const addVote = useCallback(async (vote: any) => {
    await Preferences.set({
      key: STORAGE_KEYS.CACHE.VOTES,
      value: JSON.stringify([...votesToSync, vote])
    })

    setVotesToSync(v => [...v, vote])
  }, [votesToSync, setVotesToSync])

  useEffect(() => {
    if (votesToSync.length === 0) return
    if (networkStatus === 'none') return

    const currentVote = votesToSync[0]

    vote(
      {
        variables: {
          talentId: currentVote.talentId,
          input: {
            values: currentVote.values,
            networkId: currentVote.networkId ?? '',
            talent: currentVote.isTalent,
            class: currentVote.class ?? 1
          }
        },
        refetchQueries: [
          {
            query: TalentDocument,
            variables: { id: currentVote.talentId }
          },
          {
            query: VotesDocument,
            variables: { talentId: currentVote.talentId }
          },
          {
            query: SpecialTalentsDocument,
            variables: { campId: currentVote.networkId ?? '' }
          },
          {
            query: NotificationDocument
          },
          {
            query: TalentLatestVoteDocument,
            variables: {
              talentId: currentVote.talentId,
              campId: currentVote.networkId ?? ''
            }
          }
        ],
        onError: (e) => {
          console.error(e)
        },
        onCompleted: () => {
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          const [_, ...rest] = votesToSync
          Preferences.set({
            key: STORAGE_KEYS.CACHE.VOTES,
            value: JSON.stringify(rest)
          })
          setVotesToSync(([_, ...rest]) => rest.filter(Boolean))
        }
      }
    )
  }, [networkStatus, vote, votesToSync])

  return (
        <OfflineCache.Provider value={{ addVote, votesToSync: votesToSync.length }}>
            {children}
        </OfflineCache.Provider>
  )
}

export default OfflineCacheProvider
