import { type RouteComponentProps } from 'react-router'
import { useTalentLatestVoteQuery, useTalentQuery } from '../../../lib/apollo/types'
import Page from '../../../components/Page'
import Header from '../../../components/Header'
import Content from '../../../components/Content'
import TalentShortVoteForm from '../../../components/TalentShortVoteForm'
import { useIonRouter } from '@ionic/react'

interface TalentPageProps
  extends RouteComponentProps<{
    id: string
    talentId: string
  }> {}

const TalentVotePage: React.FC<TalentPageProps> = ({ match }) => {
  const { data: talent } = useTalentQuery({
    variables: {
      id: match.params.talentId
    },
    skip: !match.params.talentId
  })

  const { data: myLatestVote, loading } = useTalentLatestVoteQuery({
    variables: {
      talentId: match.params.talentId,
      campId: match.params.id
    }
  })

  const { goBack } = useIonRouter()

  return (
        <Page className='vote'>
            <Header title={talent?.talent?.firstname ? `${talent?.talent?.firstname} ${talent?.talent?.lastname}` : '...'}></Header>
            <Content>
                <div>
                    {!loading && (
                        <TalentShortVoteForm
                            talent={talent?.talent}
                            talentId={match.params.talentId}
                            networkId={match.params.id}
                            onVote={() => { goBack() }}
                            defaultValue={myLatestVote?.talentLatestVote?.values}
                            defaultIsTalent={!!myLatestVote?.talentLatestVote?.talent}
                        />
                    )}
                </div>
            </Content>
        </Page>
  )
}

export default TalentVotePage
