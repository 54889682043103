import React, { useMemo } from 'react'

import Graph from './Graph'
import { createPieGraphs, createRadarValues } from './talentIdUtils'

interface RadarProps {
  id: string
  translations: string[]
  values: number[]
  compareValues?: number[]
  animateToValues?: number[][]
  animationIndex?: number
}

const Rader: React.FC<RadarProps> = ({
  id,
  translations,
  values,
  compareValues,
  animateToValues,
  animationIndex
}) => {
  const lineArray = useMemo(() => createRadarValues((new Array(values.length)).fill(100)), [values.length])
  const tortenArray = useMemo(() => createPieGraphs([(new Array(values.length)).fill(105)]), [values.length])
  const offset = useMemo(() => 100 / values.length / 2, [values.length])
  const points = useMemo(() => createRadarValues(values), [values])

  return (
        <>
            {lineArray.map((a, k) => (
                <line
                    // eslint-disable-next-line react/no-array-index-key
                    key={k}
                    x1='250'
                    y1='250'
                    x2={a[0]}
                    y2={a[1]}
                />
            ))}

            <Graph
                id={id}
                values={values}
                compareValues={compareValues}
                animateToValues={animateToValues}
                animationIndex={animationIndex}
            />

            {lineArray.map((a, k) => {
              const p = points[k]
              let oset = (offset * (k + 1) + (100 / 4) / 2)
              let lower = ''

              // setting upside down labels
              if (oset < 50 && oset > 25) {
                lower = '-lower'
                oset = (offset * values.length - offset * (k + 1) + (100 / 4) / 2)
              }

              return (
                    <g
                        // eslint-disable-next-line react/no-array-index-key
                        key={k}
                    >
                        <line
                            x1='250'
                            y1='250'
                            x2={a[0]}
                            y2={a[1]}
                        />
                        <text
                            className='emoLbl'
                        >
                            <textPath
                                href={`#${id}-cp${lower}`}
                                textAnchor='middle'
                                startOffset={`${oset}%`}
                            >
                                { translations[k] }
                            </textPath>
                        </text>

                        <path
                            className='tortenelement'
                            transform='translate(250,250)'
                            d={tortenArray[0][k]}
                        />
                        <g
                            className='emoVal l-global'
                        >
                            <circle
                                r='4'
                                cx={p[0]}
                                cy={p[1]}
                            />
                            <text
                                dy='.35em'
                                x='250'
                                y='250'
                            >
                                { Math.floor(values[k]) }
                                    %
                            </text>
                        </g>
                    </g>
              )
            })}
        </>
  )
}

export default Rader
