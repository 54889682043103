const STORAGE_KEYS = {
  API: {
    WHOAMI: 'api.whoami',
    LOGIN_TOKEN: 'api.login-token'
  },
  LOGIN: {
    LAST_USERNAME: 'login.lastUsername'
  },
  CACHE: {
    VOTES: 'cache.votes'
  }
}

export default STORAGE_KEYS
