import './style.scss'
import { IonButton, IonGrid, IonRow, IonText } from '@ionic/react'
import clsx from 'clsx'
import { Children, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import type { Swiper as SwiperClass } from 'swiper'
import { Controller, FreeMode, Navigation, Pagination } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Capacitor } from '@capacitor/core'

import useWindowResizeListener from '../../lib/hooks/useWindowResizeListener'

interface SliderProps {
  title?: string | null
  subtitle?: string | null
  link?: string
  itemWidth?: number
  className?: string
  freeMode?: boolean
  onSwiper?: (s: SwiperClass) => void
}

const Slider: React.FC<React.PropsWithChildren<SliderProps>> = ({
  title,
  link,
  itemWidth = 216,
  className,
  children,
  freeMode = false,
  onSwiper,
  subtitle
}) => {
  const window = useWindowResizeListener()
  const { t } = useTranslation()

  const slidesPerView = useMemo(() =>
    Math.min(
      window.width,
      860
    ) / (itemWidth + 16),
  [itemWidth, window.width])

  const isFreemode = useMemo(() => {
    if (!Capacitor.isNativePlatform() && window.width >= 768) {
      return false
    }
    return freeMode
  }, [freeMode, window.width])

  const arrayChildren = Children.toArray(children)

  if (arrayChildren.length === 0) {
    return null
  }

  return (
        <IonGrid>
            {title &&
                (
                    <IonRow>
                        <h2>
                            {title}
                        </h2>
                    </IonRow>
                )}

            {subtitle && (
                <IonRow>
                    <IonText
                        className='ion-margin-bottom n2br'
                        color='medium'
                    >
                        <span>
                            { subtitle }
                        </span>
                    </IonText>
                </IonRow>
            )}

            <IonRow
                className='ion-margin-top'
            >
                {!isFreemode
                  ? (
                    <Swiper
                        modules={[Controller, Navigation, Pagination, FreeMode]}
                        freeMode={true}
                        onSwiper={onSwiper}
                        spaceBetween={16}
                        slidesPerView={slidesPerView}
                        shortSwipes={false}
                        pagination={{
                          clickable: true
                        }}
                        className={className}
                        navigation={arrayChildren.length > slidesPerView}
                    >
                        { Children.map(arrayChildren, (child) => (
                            <SwiperSlide
                                style={{ minWidth: itemWidth }}
                            >
                                { child }
                            </SwiperSlide>
                        ))}
                    </Swiper>
                    )
                  : (() => {
                      return (
                        <div
                            className={clsx(className, 'slider__freemode')}
                        >
                            { Children.map(arrayChildren, (child) => (
                                <div
                                    className='slider__freemode__child-wrapper'
                                    style={{ width: itemWidth }}
                                >
                                    { child }
                                </div>

                            ))}
                        </div>
                      )
                    })()}
            </IonRow>

            {link && (
                <IonRow
                    className='ion-justify-content-center ion-margin-top'
                >
                    <IonButton
                        routerLink={link}
                        fill='clear'
                        color='tertiary'
                        size='small'
                    >
                        {t('buttons.showAll')}
                    </IonButton>
                </IonRow>
            )}
        </IonGrid>
  )
}

export default Slider
