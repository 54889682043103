import './Participant.scss'
import { type Participant as ParticipantData, type SpecialTalent } from '../../lib/apollo/types'
import { IonCard, IonIcon, IonText, useIonRouter } from '@ionic/react'
import TalentID from '../TalentID'
import TalentIDSkeleton from '../TalentID/TalentIDSkeleton'
import { useCallback } from 'react'
import useRating from '../../lib/hooks/useRating'
import { camera, football, star } from 'ionicons/icons'
import Button from '../Button'
import clsx from 'clsx'
import { useTranslation } from 'react-i18next'
import useToast from '../../providers/Toast/hooks'

const Participant: React.FC<{ participant: ParticipantData | SpecialTalent, campId: string, onClick?: (participant: ParticipantData | SpecialTalent) => any }> = ({ participant, campId, onClick: onCardClick }) => {
  const { t } = useTranslation()
  const { push } = useIonRouter()
  const { rating, loading, isTalent } = useRating(participant.id)
  const [present] = useToast()

  const onClick = useCallback(() => {
    if (onCardClick) {
      onCardClick(participant)
      return
    }

    if (rating.reduce((acc, n) => acc + n, 0) === 0) {
      push(`/camps/${campId}/talents/${participant.id}/vote`)
      return
    }

    push(`/camps/${campId}/talents/${participant.id}`)
  }, [campId, onCardClick, participant, push, rating])

  const onCameraClick = useCallback((e: any) => {
    e.stopPropagation()

    if (!participant.voteEnabled) {
      present(t('vote.noPermissionsByParentsGranted', { name: participant.firstname }), 'warning')
      return
    }

    push(`/camps/${campId}/talents/${participant.id}/camera`)
  }, [campId, participant.firstname, participant.id, participant.voteEnabled, present, push, t])

  return (
        <div className={clsx('participant-card__wrapper', { 'participant-card__wrapper--disabled': !participant.voteEnabled })}>
            {(participant as SpecialTalent).trainer?.length > 0 && (
                <div className='ion-padding-start'>
                    <h6>{t('talent.markedFrom')}</h6>
                {(participant as SpecialTalent).trainer?.map((tr, i) => (
                    <IonText color='medium' key={tr.id}>{i > 0 && ', '}{tr.firstname} {tr.lastname}</IonText>
                ))}
                </div>
            )}
            <IonCard className='participant-card' onClick={onClick}>
                {participant.image
                  ? (
                    <img className='participant-card__talent-image' src={participant.image} alt={`${participant.firstname} ${participant.lastname}`} />
                    )
                  : (
                    <div className='participant-card__talent-image participant-card__talent-image--placeholder' />
                    )}
                <Button className={clsx({ 'participant-card__has-image': !!participant.image }, 'participant-card__img-btn')} color='secondary' icon={camera} fill='clear' onClick={onCameraClick} mode='ios' />
                <div className='participant-card__caption'>
                    {(participant?.talentClass === 1 && <IonIcon icon={football} />)}
                    {(participant?.talentClass === 2 && <IonIcon src='/assets/icon/goalie.svg' />)}
                    {participant.firstname} {participant.lastname}
                </div>
                {isTalent && (
                    <IonIcon icon={star} className='participant-card__star'/>
                )}
                {loading
                  ? (
                    <TalentIDSkeleton className='participant-card__talent-id participant-card__talent-id--preview' />
                    )
                  : (
                    <TalentID className='participant-card__talent-id' values={rating} talentClass='1'/>
                    )}
            </IonCard>
        </div>
  )
}

export default Participant
